import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { ArticleGroup } from '../../_classes/ArticleGroup';
import { FrontendService } from './frontend.service';
import { forkJoin } from 'rxjs';
import { IngredientList } from 'src/_classes/IngredientList';
import { Router } from '@angular/router';
import { DateService } from './date.service';
import { LeadtimeService } from './leadtime.service';
import * as i0 from "@angular/core";
import * as i1 from "./frontend.service";
import * as i2 from "@angular/router";
import * as i3 from "./date.service";
import * as i4 from "./leadtime.service";
var CartService = /** @class */ (function () {
    /**
     * Component constructor
     * @param frontend_service Frontend service provides mostly api calls used inside logic
     */
    function CartService(frontendService, router, dateService, leadtimeService) {
        var _this = this;
        this.frontendService = frontendService;
        this.router = router;
        this.dateService = dateService;
        this.leadtimeService = leadtimeService;
        // Definiert, ob Kunde Ware abholt -> true: holt ab
        this.on_pickup = false;
        this.on_pickup_subject = new ReplaySubject(1);
        this.on_pickup_observable = this.on_pickup_subject.asObservable();
        /* sets the value (true, false) for delivery now or later */
        this.deliveryLater = false;
        this.deliveryLaterSubject = new ReplaySubject(1);
        this.deliveryLaterObservable = this.deliveryLaterSubject.asObservable();
        // Definiert, ob Kunde Ware abholt -> true: holt ab
        this.on_happy_hour = false;
        this.on_happy_hour_subject = new ReplaySubject(1);
        this.on_happy_hour_observable = this.on_happy_hour_subject.asObservable();
        // defines if it is lunch time
        this.onLunch = false;
        this.onLunchSubject = new ReplaySubject(1);
        this.onLunchObservable = this.onLunchSubject.asObservable();
        /* Sets the shop closed or open */
        this.shopClosed = false;
        this.shopClosedSubject = new ReplaySubject(1);
        this.shopClosedObservable = this.shopClosedSubject.asObservable();
        this.isDelivDateNow = false;
        this.isDelivDateNowSubject = new ReplaySubject(1);
        this.isDelivDateNowObservable = this.isDelivDateNowSubject.asObservable();
        /* Sets the shop closed or open if closed until tomorrow */
        this.shopClosedUntilTomorrow = false;
        this.shopClosedUntilTomorrowSubject = new ReplaySubject(1);
        this.shopClosedUntilTomorrowObservable = this.shopClosedUntilTomorrowSubject.asObservable();
        this.next_delivery_later_time = false;
        /* Set if the order is delivered during lunch time */
        this.deliveryOnLunchTime = false;
        this.deliveryOnLunchTimeSubject = new ReplaySubject(1);
        this.deliveryOnLunchTimeObservable = this.deliveryOnLunchTimeSubject.asObservable();
        /* Sets the shop closed or open */
        this.deliveryDate = this.dateService.newDate();
        this.deliveryDateSubject = new ReplaySubject(1);
        this.deliveryDateObservable = this.deliveryDateSubject.asObservable();
        this.openingHoursDeliveryLaterSubject = new ReplaySubject(1);
        this.openingHoursDeliveryLaterObservable = this.openingHoursDeliveryLaterSubject.asObservable();
        this.deliveryLaterTimeValuesSubject = new ReplaySubject(1);
        this.deliveryLaterTimeValuesObservable = this.deliveryLaterTimeValuesSubject.asObservable();
        this.noMoreDeliverySubject = new ReplaySubject(1);
        this.noMoreDeliveryObservable = this.noMoreDeliverySubject.asObservable();
        this.noMoreDeliveryLunchSubject = new ReplaySubject(1);
        this.noMoreDeliveryLunchObservable = this.noMoreDeliveryLunchSubject.asObservable();
        this.deliveryTime = '';
        this.deliveryTimeSubject = new ReplaySubject(1);
        this.deliveryTimeObservable = this.deliveryTimeSubject.asObservable();
        this.sessionStart = this.dateService.newDate();
        this.sessionStartSubject = new ReplaySubject(1);
        this.sessionStartObservable = this.sessionStartSubject.asObservable();
        this.addMinQuantitySurcharge = false;
        this.addMinQuantitySurchargeSubject = new ReplaySubject(1);
        this.addMinQuantitySurchargeObservable = this.addMinQuantitySurchargeSubject.asObservable();
        this.showPriceDifferencesPopup = false;
        this.showPriceDifferencesPopupSubject = new ReplaySubject(1);
        this.showPriceDifferencesPopupObservable = this.showPriceDifferencesPopupSubject.asObservable();
        this.showSessionExpiredPopup = false;
        this.showSessionExpiredPopupSubject = new ReplaySubject(1);
        this.showSessionExpiredPopupObservable = this.showSessionExpiredPopupSubject.asObservable();
        this.relocateDeliveryTimePopup = false;
        this.relocateDeliveryTimePopupSubject = new ReplaySubject(1);
        this.relocateDeliveryTimePopupObservable = this.relocateDeliveryTimePopupSubject.asObservable();
        // Warenkorb - beinhaltet alle hinzugefügten Artikel
        this.article_group_list = [];
        this.article_group_list_subject = new ReplaySubject(1);
        this.article_group_list_observable = this.article_group_list_subject.asObservable();
        // Anzahl der Artikel im Warenkorm
        this.article_amount = 0;
        this.article_amount_subject = new ReplaySubject(1);
        this.article_amount_observable = this.article_amount_subject.asObservable();
        /* the overall price of the cart items */
        this.priceCart = 0;
        this.priceCartSubject = new ReplaySubject(1);
        this.priceCartObservable = this.priceCartSubject.asObservable();
        this.priceCartTemp = 0;
        this.priceCartTempSubject = new ReplaySubject(1);
        this.priceCartTempObservable = this.priceCartTempSubject.asObservable();
        /* the total price of the order */
        this.priceTotal = 0;
        this.priceTotalSubject = new ReplaySubject(1);
        this.priceTotalObservable = this.priceTotalSubject.asObservable();
        // Mindermengenaufschlag
        this.min_quan_sur = 0;
        this.min_quan_sur_subject = new ReplaySubject(1);
        this.min_quan_sur_observable = this.min_quan_sur_subject.asObservable();
        // Mindestbestellwert
        this.min_val = 0;
        this.min_val_subject = new ReplaySubject(1);
        this.min_val_observable = this.min_val_subject.asObservable();
        this.tip = 0;
        this.tipSubject = new ReplaySubject(1);
        this.tipObservable = this.tipSubject.asObservable();
        this.tipFixedPercentage = -1;
        this.tipFixedPercentageSubject = new ReplaySubject(1);
        this.tipFixedPercentageObservable = this.tipFixedPercentageSubject.asObservable();
        this.changedTempArticles = 0;
        this.changedTempArticlesSubject = new ReplaySubject(1);
        this.changedTempArticlesObservable = this.changedTempArticlesSubject.asObservable();
        this.minValueTemp = 0;
        // bool defines state
        this.on_edit = false;
        this.shop_closed_countdown = false;
        this.marker = true;
        this.closedInThisDate = 0;
        this.attempt = 0;
        // private tip: number = 0;
        // init coutner for 5 possible sizes
        // TODO: make this dynamic and get actual available sizes from backend
        this.megaDealCounter = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            'total': 0,
            'active': false
        };
        this.megaDealCounterSubject = new ReplaySubject(1);
        this.megaDealCounterObservable = this.megaDealCounterSubject.asObservable();
        this.priceModifications = [];
        this.priceModificationsSubject = new ReplaySubject(1);
        this.priceModificationsObservable = this.priceModificationsSubject.asObservable();
        this.couponsArr = [];
        this.couponsArrSubject = new ReplaySubject(1);
        this.couponsArrObservable = this.couponsArrSubject.asObservable();
        // deliver_time: any;
        // deliveryLater = false;
        this.subscriptions = [];
        this.storageLoaded = false;
        this.storageLoadedSubject = new ReplaySubject(1);
        this.storageLoadedObservable = this.storageLoadedSubject.asObservable();
        this.timeValuesCreated = false;
        this.timeValuesCreatedSubject = new ReplaySubject(1);
        this.timeValuesCreatedObservable = this.timeValuesCreatedSubject.asObservable();
        this.isTabActive = true;
        document.addEventListener('visibilitychange', function () {
            _this.isTabActive = !document.hidden;
        });
        if (!this.isTabActive) {
            return;
        }
        this.getDateAndTimes();
        this.priceCartSubject.next(this.priceCart);
        this.priceCartTempSubject.next(this.priceCartTemp);
        this.priceTotalSubject.next(this.priceTotal);
        this.on_pickup_subject.next(this.on_pickup);
        this.deliveryLaterSubject.next(this.deliveryLater);
        this.shopClosedSubject.next(this.shopClosed);
        this.isDelivDateNowSubject.next(this.isDelivDateNow);
        this.shopClosedUntilTomorrowSubject.next(this.shopClosedUntilTomorrow);
        this.deliveryTimeSubject.next(this.deliveryTime);
        this.deliveryDateSubject.next(this.deliveryDate);
        this.deliveryLaterTimeValuesSubject.next(this.deliveryLaterTimeValues);
        // this.deliveryOnLunchTimeSubject.next(this.deliveryOnLunchTime);
        this.on_happy_hour_subject.next(this.on_happy_hour);
        this.onLunchSubject.next(this.onLunch);
        this.article_amount_subject.next(this.article_amount);
        this.min_val_subject.next(this.min_val);
        // this.priceTotalRegularSubject.next(this.priceTotalRegular);
        // this.priceTotalPickupSubject.next(this.priceTotalPickup);
        this.megaDealCounterSubject.next(this.megaDealCounter);
        this.showPriceDifferencesPopupSubject.next(this.showPriceDifferencesPopup);
        this.showSessionExpiredPopupSubject.next(this.showSessionExpiredPopup);
        this.addMinQuantitySurchargeSubject.next(this.addMinQuantitySurcharge);
        this.tipSubject.next(this.tip);
        this.priceModificationsSubject.next(this.priceModifications);
        if (sessionStorage.getItem('cartItems') != 'undefined' && sessionStorage.getItem('cartItems') != null && sessionStorage.getItem('cartItems') != 'null') {
            this.article_group_list = JSON.parse(sessionStorage.getItem('cartItems'));
        }
        this.article_group_list_subject.next(this.article_group_list);
        if (sessionStorage.getItem('coupons') != 'undefined') {
            this.couponsArr = JSON.parse(sessionStorage.getItem('coupons'));
        }
        this.couponsArrSubject.next(this.couponsArr);
        this.tipFixedPercentageSubject.next(this.tipFixedPercentage);
        this.min_quan_sur_subject.next(this.min_quan_sur);
        this.sessionStartSubject.next(this.sessionStart);
        this.storageLoadedSubject.next(this.storageLoaded);
        window.addEventListener('focus', function (event) {
            _this.preCheck();
        });
        /* save session start time value to storage once */
        if (!sessionStorage.getItem('sessionStart')) {
            sessionStorage.setItem('sessionStart', this.sessionStart.toString());
        }
        /* Make api and service calls and get data - fork join is used to check the completion of alle calls at once */
        forkJoin([
            /* make api calls */
            this.frontendService.getOpeningHoursNow()
        ])
            .subscribe(function (data) {
            _this.distributeServiceDataOnSuccess(data);
        }, function (err) { return console.error(err); });
        // this.recalculatePrices();
    }
    CartService.prototype.getDateAndTimes = function () {
        var _this = this;
        if (!sessionStorage.getItem('deliveryDate') || sessionStorage.getItem('deliveryDate') == 'Invalid Date') {
            this.deliveryDate = this.dateService.newDate();
            sessionStorage.setItem('deliveryDate', this.deliveryDate.toString());
        }
        if (!this.deliveryDate || this.deliveryDate.toString() == 'Invalid Date') {
            this.deliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        }
        if (!sessionStorage.getItem('deliveryTime')) {
            if (this.deliveryLaterTimeValues && this.deliveryLaterTimeValues.length > 0) {
                this.deliveryTime = this.deliveryLaterTimeValues[0].time;
            }
            else {
                this.deliveryTime = this.deliveryDate.toLocaleTimeString().substring(0, 5);
            }
            sessionStorage.setItem('deliveryTime', this.deliveryTime);
            this.deliveryTimeSubject.next(this.deliveryTime);
        }
        if (!this.deliveryTime) {
            this.deliveryTime = sessionStorage.getItem('deliveryTime');
            this.deliveryTimeSubject.next(this.deliveryTime);
        }
        this.deliveryDate.setHours(parseInt(this.deliveryTime.substring(0, 2)));
        this.deliveryDate.setMinutes(parseInt(this.deliveryTime.substring(3, 5)));
        this.deliveryDateSubject.next(this.deliveryDate);
        if (typeof this.openingHoursNow == 'undefined') {
            this.frontendService.getOpeningHoursNow().subscribe(function (data) {
                _this.openingHoursNow = data;
                _this.setDeliveryDateAndTime(_this.deliveryDate, _this.deliveryTime);
            });
        }
        else {
            this.setDeliveryDateAndTime(this.deliveryDate, this.deliveryTime);
        }
    };
    CartService.prototype.preCheck = function () {
        this.checkSession();
    };
    CartService.prototype.checkInstore = function () {
        return;
        // const isGoogleBot = navigator.userAgent.includes('Googlebot');
        var check = !!(this.isPickup() || !!(sessionStorage.getItem('tempUser')));
        if (check === false) {
            this.router.navigate(['/willkommen/start']);
        }
        else {
        }
    };
    CartService.prototype.checkRelocateDeliveryTime = function () {
        // this.deliveryTime = sessionStorage.getItem('deliveryTime');
        // this.deliveryTimeSubject.next(this.deliveryTime);
        var _this = this;
        if (this.deliveryLater) {
            this.createTimeValues(false, true).then(function () {
                /* code moved to createTimeValues() */
            });
        }
        else {
            this.frontendService.getOpeningHoursNow().subscribe(function (data) {
                _this.openingHoursNow = data;
                _this.checkShopOpenOrClosed().then(function () {
                    if (_this.shopClosedUntilTomorrow) {
                        _this.goToHome();
                    }
                });
            });
        }
    };
    CartService.prototype.closeRelocateDeliveryTimePopup = function () {
        this.relocateDeliveryTimePopup = false;
        this.relocateDeliveryTimePopupSubject.next(this.relocateDeliveryTimePopup);
    };
    /**
     * Distribute the service data to the corresponding attributes
     */
    CartService.prototype.distributeServiceDataOnSuccess = function (data) {
        var _this = this;
        /* distribute the api call response to the corresponding vars */
        // this.openingHoursNow = data[0];
        this.openingHoursNow = data;
        /* check if shop is open or closed */
        this.checkShopOpenOrClosed().then(function () {
            _this.loadCartSessionStorage().then(function () {
                _this.loadMinOrderValueSessionStorage();
            });
            _this.loadTipSessionStorage();
            /* execute to load all available values from session storage */
            _this.loadDeliveryDateAndTimeSessionStorage().then(function () {
                _this.loadTipSessionStorage();
                _this.loadPickupSessionStorage().then(function () {
                    _this.loadDeliveryLaterSessionStorage().then(function () {
                        _this.checkLunchTime() /* .then(() => { */;
                        _this.recalculatePrices();
                        /* }); */
                    });
                });
            });
        });
    };
    /**
     * Check if the shop is currently open
     */
    CartService.prototype.checkShopOpenOrClosed = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var currentDate = _this.dateService.newDate();
            var currentTime = currentDate.getHours().toString().padStart(2, '0') + ':' + currentDate.getMinutes().toString().padStart(2, '0') + ':' + currentDate.getSeconds().toString().padStart(2, '0');
            var currentD = _this.dateService.newDate();
            currentD.setHours(0, 0, 0, 0);
            var delivD = new Date(_this.deliveryDate);
            delivD.setHours(0, 0, 0, 0);
            if (currentD.getTime() == delivD.getTime() && _this.openingHoursNow.length > 0) {
                if (_this.closedInThisDate == 1) {
                    _this.shopClosed = true;
                }
                else {
                    for (var _i = 0, _a = _this.openingHoursNow; _i < _a.length; _i++) {
                        var oh = _a[_i];
                        if (currentTime >= oh.time_from && currentTime < oh.time_to) {
                            _this.shopClosed = false;
                            break;
                        }
                        else {
                            _this.shopClosed = true;
                        }
                    }
                }
            }
            if (currentD.getTime() == delivD.getTime() && _this.openingHoursNow.length > 0) {
                _this.isDelivDateNow = !_this.shopClosed;
            }
            else {
                _this.isDelivDateNow = false;
            }
            _this.isDelivDateNowSubject.next(_this.isDelivDateNow);
            /* set true if all time values are lower than current time */
            if (_this.shopClosed && currentD.getTime() == delivD.getTime() && _this.openingHoursNow.length > 0) {
                if (_this.closedInThisDate == 1) {
                    _this.shopClosedUntilTomorrow = true;
                }
                else {
                    for (var _b = 0, _c = _this.openingHoursNow; _b < _c.length; _b++) {
                        var oh = _c[_b];
                        if (currentTime >= oh.time_to) {
                            _this.shopClosedUntilTomorrow = true;
                        }
                        else {
                            _this.shopClosedUntilTomorrow = false;
                            break;
                        }
                    }
                }
            }
            if (_this.shopClosed && currentD.getTime() == delivD.getTime() && _this.openingHoursNow.length <= 0) {
                _this.shopClosed = true;
                _this.shopClosedUntilTomorrow = true;
            }
            _this.shopClosedUntilTomorrowSubject.next(_this.shopClosedUntilTomorrow);
            _this.shopClosedSubject.next(_this.shopClosed);
            resolve();
        });
    };
    /* check session time and if time has changes lunch time */
    CartService.prototype.checkSession = function () {
        if (sessionStorage.getItem('sessionStart')) {
            var sessionStart = new Date(sessionStorage.getItem('sessionStart')).getTime();
            var now = this.dateService.newDate().getTime();
            /* wait 2 hour to reload session window, if the user did not order anything */
            if ((now - sessionStart) > 7200000) {
                this.showSessionExpiredPopup = true;
                this.showSessionExpiredPopupSubject.next(this.showSessionExpiredPopup);
            }
        }
    };
    /**
     * Load the cart items which are stored in session storage (eg. when user reloads the page)
     */
    CartService.prototype.loadCartSessionStorage = function (coupons, checkPriceDiff) {
        var _this = this;
        if (coupons === void 0) { coupons = false; }
        if (checkPriceDiff === void 0) { checkPriceDiff = false; }
        return new Promise(function (resolve) {
            var priceCartTemp = _this.priceTotal;
            _this.showPriceDifferencesPopup = false;
            _this.showPriceDifferencesPopupSubject.next(_this.showPriceDifferencesPopup);
            _this.storageLoaded = false;
            _this.storageLoadedSubject.next(_this.storageLoaded);
            if (sessionStorage.getItem('cartItems')) {
                var loadedCartItems_1 = [];
                if (sessionStorage.getItem('cartItems') != 'undefined' && sessionStorage.getItem('cartItems') != null && sessionStorage.getItem('cartItems') != 'null') {
                    loadedCartItems_1 = JSON.parse(sessionStorage.getItem('cartItems'));
                }
                var resp_1;
                var couponsInSession_1;
                couponsInSession_1 = [];
                if (sessionStorage.getItem('coupons') != 'undefined' && sessionStorage.getItem('coupons') != null && sessionStorage.getItem('coupons') != 'null') {
                    couponsInSession_1 = JSON.parse(sessionStorage.getItem('coupons'));
                }
                var tp = sessionStorage.getItem('tip');
                var tpr = sessionStorage.getItem('tipFixedPercentage');
                // this.getDelivDateTime();
                if (!sessionStorage.getItem('deliveryDate') || sessionStorage.getItem('deliveryDate') == 'Invalid Date') {
                    _this.deliveryDate = _this.dateService.newDate();
                    sessionStorage.setItem('deliveryDate', _this.deliveryDate.toString());
                }
                if (!_this.deliveryDate || _this.deliveryDate.toString() == 'Invalid Date') {
                    _this.deliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
                }
                if (!sessionStorage.getItem('deliveryTime')) {
                    if (_this.deliveryLaterTimeValues.length > 0) {
                        _this.deliveryTime = _this.deliveryLaterTimeValues[0].time;
                    }
                    else {
                        _this.deliveryTime = _this.deliveryDate.toLocaleTimeString().substring(0, 5);
                    }
                    sessionStorage.setItem('deliveryTime', _this.deliveryTime);
                    _this.deliveryTimeSubject.next(_this.deliveryTime);
                }
                if (!_this.deliveryTime) {
                    _this.deliveryTime = sessionStorage.getItem('deliveryTime');
                }
                var paymentType = '';
                if (sessionStorage.getItem('payment_type')) {
                    paymentType = sessionStorage.getItem('payment_type');
                }
                var userData = sessionStorage.getItem('tempUser');
                var confirmDoubleOrder = '0';
                if (sessionStorage.getItem('confirm_double_order')) {
                    confirmDoubleOrder = sessionStorage.getItem('confirm_double_order');
                }
                var userAddressId = '';
                if (sessionStorage.getItem('user_address_id')) {
                    userAddressId = sessionStorage.getItem('user_address_id');
                }
                _this.deliveryDate.setHours(parseInt(_this.deliveryTime.substring(0, 2)));
                _this.deliveryDate.setMinutes(parseInt(_this.deliveryTime.substring(3, 5)));
                _this.deliveryDateSubject.next(_this.deliveryDate);
                _this.frontendService.getCartItems({
                    'article': loadedCartItems_1,
                    'deliveryType': _this.on_pickup ? 'pickup' : 'delivery',
                    'deliveryDate': _this.deliveryDate.toString(),
                    'tip': (tp == 'NaN' || tp == 'null' || tp == 'undefined') ? '0' : tp,
                    'tipPercentage': (tpr == 'NaN' || tpr == 'null' || tpr == 'undefined') ? '0' : tpr,
                    'minValAdd': _this.min_quan_sur,
                    'minVal': _this.min_val,
                    'minValIsAdded': _this.addMinQuantitySurcharge,
                    'payment_type': paymentType,
                    'couponCode': couponsInSession_1,
                    'user': userData,
                    'doubleOrderConfirmed': confirmDoubleOrder,
                    'user_address_id': userAddressId
                })
                    .then(function (data) {
                    resp_1 = data;
                    sessionStorage.setItem('cartItems', JSON.stringify(resp_1.article));
                    if (typeof data.minValIsAdded !== 'undefined') {
                        _this.setAddMinQuantitySurcharge(data.minValIsAdded);
                    }
                    sessionStorage.setItem('tip', resp_1.tip);
                    _this.tip = resp_1.tip;
                    _this.tipSubject.next(_this.tip);
                    sessionStorage.setItem('tipFixedPercentage', resp_1.tipPercentage);
                    _this.tipFixedPercentage = resp_1.tipPercentage;
                    _this.tipFixedPercentageSubject.next(_this.tipFixedPercentage);
                    if (coupons) {
                        sessionStorage.setItem('coupons', JSON.stringify(resp_1.couponCode));
                        _this.couponsArr = resp_1.couponCode;
                        _this.couponsArrSubject.next(_this.couponsArr);
                    }
                    else {
                        if (resp_1.couponCode.coupon_content == couponsInSession_1.coupon_content
                            && resp_1.couponCode.errorString && resp_1.couponCode.errorString != '') {
                            sessionStorage.setItem('coupons', 'null');
                            _this.couponsArr = [];
                            _this.couponsArrSubject.next(_this.couponsArr);
                        }
                    }
                    _this.article_amount = resp_1.total_amount;
                    _this.article_amount_subject.next(_this.article_amount);
                    _this.priceTotal = resp_1.total_sum;
                    _this.priceTotalSubject.next(_this.priceTotal);
                    _this.priceModifications = resp_1.modifications;
                    _this.priceModificationsSubject.next(_this.priceModifications);
                    if (resp_1.payment_discount) {
                        sessionStorage.setItem('payment_discount', resp_1.payment_discount);
                    }
                    if (resp_1.order_doubled != undefined) {
                        var doub = '0';
                        if (resp_1.order_doubled && resp_1.order_doubled != 'false') {
                            doub = '1';
                        }
                        sessionStorage.setItem('order_doubled', doub);
                    }
                    // sessionStorage.setItem('order_doubled', '1');
                    loadedCartItems_1 = resp_1.article;
                    _this.article_group_list = [];
                    for (var _i = 0, loadedCartItems_2 = loadedCartItems_1; _i < loadedCartItems_2.length; _i++) {
                        var cartItem = loadedCartItems_2[_i];
                        var product_coupon = '';
                        if (cartItem.product_coupon) {
                            product_coupon = cartItem.product_coupon;
                        }
                        var onlyCoupon = 0;
                        if (cartItem.onlyCoupon) {
                            onlyCoupon = cartItem.onlyCoupon;
                        }
                        var article_group = new ArticleGroup(cartItem.article, cartItem.prices, cartItem.size_index, cartItem.article.category_id, cartItem.added_ingredients, product_coupon, onlyCoupon);
                        for (var i = 0; i < cartItem.amount; i++) {
                            _this.addNewSingleArticleGroup(article_group);
                        }
                    }
                    _this.article_group_list_subject.next(_this.article_group_list);
                    _this.min_quan_sur = resp_1.minValAdd <= 0 ? 0 : resp_1.minValAdd;
                    _this.min_quan_sur_subject.next(_this.min_quan_sur);
                    _this.priceCart = resp_1.base_sum;
                    _this.priceCartSubject.next(_this.priceCart);
                    if (_this.showPriceDifferencesPopup === false && checkPriceDiff) {
                        if (priceCartTemp !== 0) {
                            if (priceCartTemp !== _this.priceTotal) {
                                _this.priceCartTemp = priceCartTemp;
                                _this.priceCartTempSubject.next(_this.priceCartTemp);
                                _this.showPriceDifferencesPopup = true;
                                _this.showPriceDifferencesPopupSubject.next(_this.showPriceDifferencesPopup);
                            }
                        }
                    }
                    _this.storageLoaded = true;
                    _this.storageLoadedSubject.next(_this.storageLoaded);
                    resolve();
                });
            }
            else {
                _this.storageLoaded = true;
                _this.storageLoadedSubject.next(_this.storageLoaded);
                resolve();
            }
        });
    };
    /**
     * Load the minimum order value which is stored in session storage (eg. when user reloads the page)
     */
    CartService.prototype.loadMinOrderValueSessionStorage = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (sessionStorage.getItem('minOrderValue')) {
                var loadedMinOrderValue = JSON.parse(sessionStorage.getItem('minOrderValue'));
                _this.setMinVal(loadedMinOrderValue);
            }
            resolve();
        });
    };
    /**
     * Load the values for pickup or delivery from session storage
     */
    CartService.prototype.loadPickupSessionStorage = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (sessionStorage.getItem('pickup')) {
                var loadedPickup = JSON.parse(sessionStorage.getItem('pickup'));
                _this.setPickup(loadedPickup);
            }
            resolve();
        });
    };
    /**
     * Load the value for delivery later - determines if the user wishes to postpone the order
     */
    CartService.prototype.loadDeliveryLaterSessionStorage = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (sessionStorage.getItem('deliveryLater')) {
                var loadedDeliveryLater = JSON.parse(sessionStorage.getItem('deliveryLater')) == 1 ? true : false;
                _this.setDeliveryLater(loadedDeliveryLater);
            }
            resolve();
        });
    };
    /**
     * Load the given delivery date
     */
    CartService.prototype.loadDeliveryDateAndTimeSessionStorage = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (sessionStorage.getItem('deliveryDate') && sessionStorage.getItem('deliveryTime') && sessionStorage.getItem('deliveryDate') != 'Invalid Date') {
                var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
                var loadedDeliveryTime_1 = sessionStorage.getItem('deliveryTime');
                _this.setDeliveryDateAndTime(loadedDeliveryDate, loadedDeliveryTime_1).then(function () {
                    resolve();
                });
                // this.recalculatePrices();
            }
            else if (!sessionStorage.getItem('deliveryDate') || (sessionStorage.getItem('deliveryDate') && sessionStorage.getItem('deliveryDate') == 'Invalid Date')) {
                var loadedDeliveryDate = _this.dateService.newDate();
                var loadedDeliveryTime;
                if (!sessionStorage.getItem('deliveryTime')) {
                    loadedDeliveryTime = loadedDeliveryDate.toLocaleTimeString().substring(0, 5);
                }
                else {
                    loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
                }
                _this.setDeliveryDateAndTime(loadedDeliveryDate, loadedDeliveryTime).then(function () {
                    resolve();
                });
            }
            resolve();
        });
    };
    /**
     * Load the given tip
     */
    CartService.prototype.loadTipSessionStorage = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (sessionStorage.getItem('tip')) {
                var tipFixedPercentage = parseFloat(sessionStorage.getItem('tipFixedPercentage'));
                if (parseFloat(sessionStorage.getItem('tipFixedPercentage')) > 0) {
                    _this.setTipFixedPercentage(tipFixedPercentage);
                    _this.calcTipPercentage(tipFixedPercentage);
                }
                else {
                    _this.setTipFixedPercentage(0);
                    _this.setTip(parseFloat(sessionStorage.getItem('tip')));
                }
            }
            resolve();
        });
    };
    CartService.prototype.loadIngredientList = function (productSubCategoryId) {
        var ingredientList = this.frontendService.getIngListBySubCat(productSubCategoryId);
    };
    CartService.prototype.checkCouponProductInCart = function (couponData) {
        if (sessionStorage.getItem('cartItems')) {
            var cart_item = JSON.parse(sessionStorage.getItem('cartItems'));
            var tovs = [];
            if (typeof cart_item === 'object' && cart_item.length > 0) {
                for (var _i = 0, cart_item_1 = cart_item; _i < cart_item_1.length; _i++) {
                    var item = cart_item_1[_i];
                    tovs[item.article.id] = tovs[item.article.id] > 0 ? tovs[item.article.id] + item.amount : item.amount;
                    if (item.onlyCoupon && item.onlyCoupon === '0') {
                        item.onlyCoupon = 0;
                    }
                    if ((item.article.is_coupon_product && couponData.only_coupon_in_cart) || item.onlyCoupon /*  && item.article.id === couponData.product.id */) {
                        return true;
                    }
                }
                if (couponData.product && tovs[couponData.product.id] && tovs[couponData.product.id] >= couponData.redeem_count) {
                    return true;
                }
            }
        }
        return false;
    };
    /**
     * Calculate the party pizza mega deal - if user has 10 or more pizzas on cart the prices changes accordingly
     */
    CartService.prototype.calcMegaDeal = function () {
        if (!this.onLunch) {
            if (this.megaDealCounter.total > 9) {
                this.megaDealCounter.active = true;
            }
            else {
                this.megaDealCounter.active = false;
            }
        }
        else {
            /* FEATURE: check if size "32er" has 10 or more items when lunch time */
            if (this.megaDealCounter[1] > 9) {
                this.megaDealCounter.active = true;
            }
            else {
                this.megaDealCounter.active = false;
            }
        }
    };
    /**
     * Calculate the tip value by percentage
     * @param percentage The given tip percentage
     */
    CartService.prototype.calcTipPercentage = function (percentage) {
        if (percentage === void 0) { percentage = false; }
        if (percentage === false && sessionStorage.getItem('tipFixedPercentage')) {
            this.tip = parseFloat((this.priceCart * parseFloat(sessionStorage.getItem('tipFixedPercentage'))).toFixed(2));
            this.setTip(this.tip);
        }
        else {
            this.tip = parseFloat((this.priceCart * percentage).toFixed(2));
            this.setTip(this.tip);
        }
    };
    // calcTipPercentage(eventValue: string) {
    //     let tip = parseFloat((this.price_sum * parseFloat(eventValue)).toFixed(2));
    //     this.tipFormGroup.get('tipValue').setValue(tip);
    //     this.cart_service.setTip(tip);
    //     sessionStorage.setItem('tipFixedPercentage', this.tipFormGroup.get('tipFixedPercentage').value);
    // }
    /**
     * Calculate the current minimum order value for the given zip and district
     * @param plz The given zip code
     * @param district The given district
     */
    CartService.prototype.calcMinVal = function (plz, district) {
        var _this = this;
        this.frontendService.getMinVal(plz, district).subscribe(function (data) {
            _this.setMinVal(data);
        });
    };
    /**
     * Set true or false - shop is open or not
     */
    CartService.prototype.setShopClosed = function (shopClosed) {
        this.shopClosed = shopClosed;
        this.shopClosedSubject.next(this.shopClosed);
    };
    /**
     * Set shop is currently having happy hour
     * @param state Define true or false
     */
    CartService.prototype.setHappyHour = function (state) {
        this.on_happy_hour = state;
        this.on_happy_hour_subject.next(this.on_happy_hour);
    };
    /**
     * Set if the shop currently has lunch time
     * @param state Define true or false
     */
    CartService.prototype.setLunch = function (state) {
        this.onLunch = state;
        this.onLunchSubject.next(this.onLunch);
        sessionStorage.setItem('lunch', this.onLunch + '');
    };
    /**
     * Sets the global value for if the user would have items delivered or wishes to pick them up
     * @param value Set value for delivery or pickup
     */
    CartService.prototype.setPickup = function (value) {
        this.on_pickup = value;
        this.on_pickup_subject.next(this.on_pickup);
        if (value) {
            sessionStorage.removeItem('minOrderValue');
            this.setMinVal('0');
        }
        this.shopClosedUntilTomorrow = false;
        this.shopClosedUntilTomorrowSubject.next(this.shopClosedUntilTomorrow);
    };
    CartService.prototype.isPickup = function () {
        return this.on_pickup;
    };
    /**
     * Set new cart items array after altering in some way
     */
    CartService.prototype.setCartItems = function (cartItems) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.article_group_list = cartItems;
            _this.article_group_list_subject.next(_this.article_group_list);
            sessionStorage.setItem('cartItems', JSON.stringify(_this.article_group_list));
            resolve();
        });
    };
    /**
     * Sets the global value for if the user would have items delivered or wishes to pick them up
     * @param value Set value for delivery or pickup
     */
    CartService.prototype.setDeliveryLater = function (value) {
        this.deliveryLater = value;
        this.deliveryLaterSubject.next(this.deliveryLater);
    };
    /**
     * Set the current delivery date
     * @param value The date object
     */
    CartService.prototype.setDeliveryDateAndTime = function (date, time) {
        var _this = this;
        return new Promise(function (resolve) {
            /* if shop has closed until end of day, overwrite date to tomorrow */
            var today = _this.dateService.newDate();
            today.setHours(0, 0, 0, 0);
            var selDate = new Date(date.getTime());
            selDate.setHours(0, 0, 0, 0);
            if (_this.shopClosedUntilTomorrow && today.getTime() == selDate.getTime()) {
                var tomorrow = _this.dateService.newDate();
                tomorrow.setDate(_this.dateService.newDate().getDate() + 1);
                date = tomorrow;
            }
            _this.deliveryTime = time;
            _this.deliveryDate = date;
            _this.deliveryDate.setHours(parseInt(time.toString().substring(0, 2)));
            _this.deliveryDate.setMinutes(parseInt(time.toString().substring(3, 5)));
            _this.deliveryDateSubject.next(_this.deliveryDate);
            _this.deliveryTimeSubject.next(_this.deliveryTime);
            sessionStorage.setItem('deliveryDate', _this.deliveryDate.toString());
            sessionStorage.setItem('deliveryTime', _this.deliveryTime.toString());
            /* temp values for the date request */
            var send_date = { 'year': date.getFullYear(), 'month': date.getMonth() + 1, 'day': date.getDate() };
            var query = send_date.year + '-' + send_date.month + '-' + send_date.day;
            /* request the opening hours for the given date, and check if its lunch time, then create the array for the time vlues to choose from */
            _this.frontendService.onGetOpeningHoursByDate(query).then(function (data) {
                if (typeof data[0] != 'undefined' && typeof data[0].closedInThisDate != 'undefined' && data[0].closedInThisDate == 1) {
                    data[0].time_from = null;
                    data[0].time_to = null;
                }
                // this.openingHoursNow = data;
                if (typeof data[0] != 'undefined' && typeof data[0].closedInThisDate != 'undefined') {
                    _this.closedInThisDate = data[0].closedInThisDate;
                }
                else {
                    _this.closedInThisDate = 1;
                }
                if (_this.closedInThisDate == 1) {
                    _this.openingHoursDeliveryLater = [];
                }
                else {
                    _this.openingHoursDeliveryLater = data;
                }
                _this.checkShopOpenOrClosed().then(function () {
                    _this.createTimeValues(true).then(function () {
                        /* code moved to createTimeValues() */
                    });
                });
            });
            resolve();
        });
    };
    CartService.prototype.resetDeliveryDateTime = function () {
        this.deliveryTime = '';
        this.deliveryTimeSubject.next(this.deliveryTime);
        sessionStorage.setItem('deliveryTime', this.deliveryTime);
    };
    /**
     * Predefine object for available time values according to current or selected delivery date
     */
    CartService.prototype.createTimeValues = function (extraCalc, extraCalcReloc) {
        var _this = this;
        if (extraCalc === void 0) { extraCalc = false; }
        if (extraCalcReloc === void 0) { extraCalcReloc = false; }
        this.setLunch(false);
        return new Promise(function (resolve) {
            if (!_this.deliveryLaterTimeValues) {
                _this.deliveryLaterTimeValues = [];
            }
            var typeDeliv = (sessionStorage.getItem('pickup') === '1') ? 'pickup' : 'delivery';
            var dateArr = { 'year': _this.deliveryDate.getFullYear(), 'month': _this.deliveryDate.getMonth() + 1, 'day': _this.deliveryDate.getDate() };
            var delivDate = dateArr.day + '-' + dateArr.month + '-' + dateArr.year;
            _this.frontendService.getOpeningHoursArr(typeDeliv, delivDate, _this.deliveryDate.toString()).then(function (data) {
                if (data['times']) {
                    _this.deliveryLaterTimeValues = data['times'];
                    for (var i = 0; i < _this.deliveryLaterTimeValues.length; i++) {
                        if (_this.deliveryLaterTimeValues[i].time == _this.deliveryTime) {
                            if (_this.deliveryLaterTimeValues[i].label.includes('chnellstmöglich')) {
                                sessionStorage.setItem('Schnell', '1');
                                sessionStorage.setItem('Schnell_text', _this.deliveryLaterTimeValues[i].label);
                            }
                            else {
                                sessionStorage.setItem('Schnell', '0');
                                sessionStorage.setItem('Schnell_text', '');
                            }
                        }
                    }
                }
                else {
                    _this.deliveryLaterTimeValues = [];
                }
                _this.deliveryDate = new Date(data['queryDate']);
                sessionStorage.setItem('deliveryDate', _this.deliveryDate.toString());
                _this.deliveryDateSubject.next(_this.deliveryDate);
                _this.deliveryLaterTimeValuesSubject.next(_this.deliveryLaterTimeValues);
                _this.noMoreDelivery = data['noMoreDelivery'] ? data['noMoreDelivery'] : false;
                _this.noMoreDeliverySubject.next(_this.noMoreDelivery);
                _this.noMoreDeliveryLunch = data['noMoreDeliveryLunch'] ? data['noMoreDeliveryLunch'] : false;
                _this.noMoreDeliveryLunchSubject.next(_this.noMoreDeliveryLunch);
                _this.checkLunchTime();
                if (extraCalc) {
                    if (_this.deliveryLaterTimeValues.length > 0) {
                        if (!sessionStorage.getItem('deliveryTime')) {
                            _this.deliveryTime = _this.deliveryLaterTimeValues[0].time;
                            sessionStorage.setItem('deliveryTime', _this.deliveryTime);
                            _this.deliveryTimeSubject.next(_this.deliveryTime);
                        }
                        /* save to session storage */
                        sessionStorage.setItem('deliveryDate', _this.deliveryDate.toString());
                        var available_time_1 = null;
                        _this.deliveryLaterTimeValues.forEach(function (time) {
                            if (time.time === _this.deliveryTime) {
                                available_time_1 = _this.deliveryTime;
                            }
                        });
                        if (!sessionStorage.getItem('sofortAfterPopapClick')) {
                            _this.deliveryTime = (available_time_1) ? available_time_1 : _this.deliveryLaterTimeValues[0].time;
                            _this.deliveryTimeSubject.next(_this.deliveryTime);
                            sessionStorage.setItem('deliveryTime', _this.deliveryTime);
                        }
                        _this.checkShopOpenOrClosed();
                        _this.checkLunchTime() /* .then(() => { */;
                        _this.alterCartItemFreeAddables().then(function () {
                            _this.recalculatePrices().then(function () {
                                resolve();
                            });
                        });
                        /* }); */
                    }
                    else {
                        var loadedDeliveryDate = new Date();
                        _this.deliveryTime = loadedDeliveryDate.getHours().toString().padStart(2, '0') + ':' + loadedDeliveryDate.getMinutes().toString().padStart(2, '0');
                        sessionStorage.setItem('deliveryTime', _this.deliveryTime);
                        _this.deliveryTimeSubject.next(_this.deliveryTime);
                    }
                }
                if (extraCalcReloc) {
                    var available_time_reloc_1 = null;
                    var smollerClosestTime_1 = 0;
                    var biggerClosestTime_1 = 2400;
                    var smollerClosestTimeStr_1 = '';
                    var biggerClosestTimeStr_1 = '';
                    if (_this.deliveryLaterTimeValues.length > 0) {
                        var delivTimeInt_1 = parseInt(_this.deliveryTime.replace(':', ''));
                        _this.deliveryLaterTimeValues.forEach(function (time) {
                            if (time.time === _this.deliveryTime) {
                                available_time_reloc_1 = _this.deliveryTime;
                            }
                            else {
                                var slotInt = parseInt(time.time.replace(':', ''));
                                if (delivTimeInt_1 > slotInt && slotInt > smollerClosestTime_1) {
                                    smollerClosestTimeStr_1 = time.time;
                                    smollerClosestTime_1 = slotInt;
                                }
                                else if (delivTimeInt_1 < slotInt && slotInt < biggerClosestTime_1) {
                                    biggerClosestTimeStr_1 = time.time;
                                    biggerClosestTime_1 = slotInt;
                                }
                            }
                        });
                        if (null === available_time_reloc_1) {
                            var newDeliveryTime = _this.deliveryLaterTimeValues[0].time;
                            if (biggerClosestTime_1 < 2400) {
                                newDeliveryTime = biggerClosestTimeStr_1;
                            }
                            else if (smollerClosestTime_1 > 0) {
                                newDeliveryTime = smollerClosestTimeStr_1;
                            }
                            _this.deliveryTime = newDeliveryTime;
                            sessionStorage.setItem('deliveryTime', _this.deliveryTime);
                            _this.deliveryTimeSubject.next(_this.deliveryTime);
                            _this.relocateDeliveryTimePopup = true;
                            _this.relocateDeliveryTimePopupSubject.next(_this.relocateDeliveryTimePopup);
                            // const prevDeliveryTimeObject = this.dateService.newDate();
                            // // tslint:disable-next-line: radix
                            // prevDeliveryTimeObject.setHours(parseInt(this.deliveryTime.toString().substring(0, 2)));
                            // // tslint:disable-next-line: radix
                            // prevDeliveryTimeObject.setMinutes(parseInt(this.deliveryTime.toString().substring(3, 5)));
                            // const DeliveryTimeObject = this.dateService.newDate();
                            // DeliveryTimeObject.setHours(this.deliveryLaterTimeValues[0].time.substring(0, 2));
                            // DeliveryTimeObject.setMinutes(this.deliveryLaterTimeValues[0].time.substring(3, 5));
                            // let openHourIndex: any = null;
                            // let prevOpenHourIndex: any = null;
                            // for (let index = 0; index < this.openingHoursDeliveryLater.length; index++) {
                            //     const open_from = this.dateService.newDate();
                            //     // tslint:disable-next-line: radix
                            //     open_from.setHours(parseInt(this.openingHoursDeliveryLater[index].time_from.substring(0, 2)));
                            //     // tslint:disable-next-line: radix
                            //     open_from.setMinutes(parseInt(this.openingHoursDeliveryLater[index].time_from.substring(3, 5)));
                            //     const open_to = this.dateService.newDate();
                            //     // tslint:disable-next-line: radix
                            //     open_to.setHours(parseInt(this.openingHoursDeliveryLater[index].time_to.substring(0, 2)));
                            //     // tslint:disable-next-line: radix
                            //     open_to.setMinutes(parseInt(this.openingHoursDeliveryLater[index].time_to.substring(3, 5)));
                            //     if (prevDeliveryTimeObject >= open_from && prevDeliveryTimeObject <= open_to) {
                            //         prevOpenHourIndex = index;
                            //     }
                            //     if (DeliveryTimeObject >= open_from && DeliveryTimeObject <= open_to) {
                            //         openHourIndex = index;
                            //     }
                            // }
                            // const andOfDay = this.dateService.newDate();
                            // andOfDay.setHours(23, 59, 59, 59);
                            // if (andOfDay > this.deliveryDate) {
                            //     if( prevOpenHourIndex !== openHourIndex ){
                            //         this.setDeliveryLater(false);
                            //         sessionStorage.setItem('deliveryLater', '0');
                            //         this.relocateDeliveryTimePopup = true;
                            //         this.relocateDeliveryTimePopupSubject.next(this.relocateDeliveryTimePopup);
                            //     }else{
                            //         this.deliveryTime = this.deliveryLaterTimeValues[0].time;
                            //         this.deliveryTimeSubject.next(this.deliveryTime);
                            //         sessionStorage.setItem('deliveryTime', this.deliveryTime);
                            //         this.relocateDeliveryTimePopup = true;
                            //         this.relocateDeliveryTimePopupSubject.next(this.relocateDeliveryTimePopup);
                            //     }
                            // }
                        }
                        else {
                        }
                    }
                    else {
                        var andOfDay = _this.dateService.newDate();
                        andOfDay.setHours(23, 59, 59, 59);
                        if (andOfDay > _this.deliveryDate) {
                            _this.setDeliveryLater(false);
                            sessionStorage.setItem('deliveryLater', '0');
                            _this.relocateDeliveryTimePopup = true;
                            _this.relocateDeliveryTimePopupSubject.next(_this.relocateDeliveryTimePopup);
                        }
                    }
                }
                _this.timeValuesCreated = true;
                _this.timeValuesCreatedSubject.next(_this.timeValuesCreated);
                resolve();
            });
            resolve();
        });
    };
    /**
     * Helper function: Sort array of objects by given key
     */
    CartService.prototype.compareValues = function (key, order) {
        if (order === void 0) { order = 'asc'; }
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }
            var varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            var varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];
            var comparison = 0;
            if (varA > varB) {
                comparison = 1;
            }
            else if (varA < varB) {
                comparison = -1;
            }
            return ((order === 'desc') ? (comparison * -1) : comparison);
        };
    };
    /**
      * Check if the selected time and date interects with lunch time
      */
    CartService.prototype.checkLunchTime = function () {
        // return new Promise((resolve) => {
        /* preset the lunch time to false, for component and global service */
        this.deliveryOnLunchTime = false;
        this.setLunch(false);
        var now = this.dateService.newDate();
        var delivery_time = null;
        if (this.deliveryLater === false) {
            delivery_time = now.getHours().toString().padStart(2, '0') + ':' + now.getMinutes().toString().padStart(2, '0');
        }
        else {
            delivery_time = this.deliveryTime + ':00';
        }
        /* check if the chosen time is at lunch time (mainly for price benefits) */
        if (this.openingHoursDeliveryLater && this.openingHoursDeliveryLater[0] && this.openingHoursDeliveryLater[0].lunch.time_from) {
            if (this.openingHoursDeliveryLater[0].lunch.time_from && this.openingHoursDeliveryLater[0].lunch.time_to) {
                if (delivery_time >= this.openingHoursDeliveryLater[0].lunch.time_from && delivery_time <= this.openingHoursDeliveryLater[0].lunch.time_to) {
                    // this.deliveryOnLunchTime = true;
                    // this.deliveryOnLunchTimeSubject.next(this.deliveryOnLunchTime);
                    this.setLunch(true);
                }
            }
        }
        if (this.openingHoursDeliveryLater && this.openingHoursDeliveryLater[1] && this.openingHoursDeliveryLater[1].lunch.time_from) {
            if (this.openingHoursDeliveryLater[1].lunch.time_from && this.openingHoursDeliveryLater[1].lunch.time_to) {
                if (delivery_time >= this.openingHoursDeliveryLater[1].lunch.time_from && delivery_time <= this.openingHoursDeliveryLater[1].lunch.time_to) {
                    // this.deliveryOnLunchTime = true;
                    // this.deliveryOnLunchTimeSubject.next(this.deliveryOnLunchTime);
                    this.setLunch(true);
                }
            }
        }
        /* Resolve the promise at the end */
        //     resolve();
        // });
    };
    CartService.prototype.alterCartItemFreeAddables = function () {
        var _this = this;
        return new Promise(function (resolve) {
            /* correct the amount fo free addables regarding to the new delivery time (at lunch or not at lunch) */
            if (_this.onLunch) {
                var _loop_1 = function (itemIndex) {
                    var item = _this.article_group_list[itemIndex];
                    if (item.added_ingredients.length > 0) {
                        for (var ingIndex = 0; ingIndex < item.added_ingredients.length; ingIndex++) {
                            var ingredient = item.added_ingredients[ingIndex];
                            var priceNode = ingredient.ingredients.price[item.size_index];
                            if (priceNode.only_at_lunch && priceNode.is_free_addable) {
                                for (var amountIndex = 0; amountIndex < ingredient.amount; amountIndex++) {
                                    if (ingredient.free_amount < ingredient.max_free_addable) {
                                        _this.article_group_list[itemIndex].added_ingredients[ingIndex].free_amount++;
                                    }
                                }
                            }
                        }
                    }
                    else {
                        if (!item.article.price[item.size_index].free_addables_reached) {
                            // let max_free_addable = 0;
                            // if (this.ingredient_list[ingredientGroupIndex].sizes_free_addables[this.current_size]) {
                            //     max_free_addable = this.ingredient_list[ingredientGroupIndex].sizes_free_addables[this.current_size].free_addable_amount;
                            // }
                            _this.frontendService.getIngredientsByProductSubCategory(item.sub_cat).then(function (data) {
                                var ingredientList = data;
                                for (var key in ingredientList) {
                                    if (ingredientList.hasOwnProperty(key)) {
                                        var ingCatIndex = parseInt(key, 10);
                                        var element = ingredientList[key];
                                        if (element.length) {
                                            var max_addable = 1;
                                            if (element.max_addable) {
                                                max_addable = element.max_addable;
                                            }
                                            for (var ingIndex = 0; ingIndex < element.ingredients.length; ingIndex++) {
                                                var ingredient = element.ingredients[ingIndex];
                                                // TODO: fix the amount of max_free_addable when creating IngredientList (currently 1, but should depend on actual setting)
                                                if (ingredient.id === item.article.price[item.size_index].default_free_addable_id) {
                                                    item.added_ingredients.push(new IngredientList(ingredient, ingCatIndex, ingIndex, true, 1, max_addable));
                                                }
                                            }
                                        }
                                    }
                                }
                            });
                        }
                    }
                };
                /* add free addables until max addables reached */
                for (var itemIndex = 0; itemIndex < _this.article_group_list.length; itemIndex++) {
                    _loop_1(itemIndex);
                }
            }
            else {
                /* remove free addables until amount is 0 */
                for (var itemIndex = 0; itemIndex < _this.article_group_list.length; itemIndex++) {
                    var item = _this.article_group_list[itemIndex];
                    for (var ingIndex = 0; ingIndex < item.added_ingredients.length; ingIndex++) {
                        var ingredient = item.added_ingredients[ingIndex];
                        var priceNode = ingredient.ingredients.price[item.size_index];
                        if (priceNode.only_at_lunch && priceNode.is_free_addable) {
                            for (var amountIndex = 0; amountIndex < ingredient.amount; amountIndex++) {
                                if (ingredient.free_amount > 0) {
                                    _this.article_group_list[itemIndex].added_ingredients[ingIndex].free_amount--;
                                }
                            }
                        }
                    }
                }
            }
            _this.setCartItems(_this.article_group_list).then(function () {
                // this.recalculatePrices();
                resolve();
            });
        });
    };
    /**
     * Set the value for addMinQuantitySurcharge from cart page (if the user added the surcharge or not)
     * @param value Value for "addMinQuantitySurcharge"
     */
    CartService.prototype.setAddMinQuantitySurcharge = function (value) {
        this.addMinQuantitySurcharge = value;
        this.addMinQuantitySurchargeSubject.next(this.addMinQuantitySurcharge);
    };
    /**
     * Set the current minimum order value
     * @param min_val The minimum order value
     */
    CartService.prototype.setMinVal = function (min_val) {
        if (this.on_pickup) {
            sessionStorage.removeItem('minOrderValue');
            this.min_val = 0;
            this.min_val_subject.next(this.min_val);
        }
        else {
            this.min_val = +min_val;
            // this.min_quan_sur = (this.min_val - this.priceCart) < 0 ? 0 : (this.min_val - this.priceCart);
            this.min_val_subject.next(this.min_val);
            // this.min_quan_sur_subject.next(this.min_quan_sur);
            // set temp min value for togglePickup switch
            this.minValueTemp = this.min_val;
            sessionStorage.setItem('minOrderValue', JSON.stringify(this.min_val));
        }
        this.recalculatePrices();
    };
    /**
     * Set the index of the current product which will be edited after already in cart
     * @param index The index in the cart items array
     */
    CartService.prototype.setEditIndex = function (index) {
        this.edit_index = index;
        this.on_edit = true;
    };
    /**
     * Add a new product to the current items in cart
     */
    CartService.prototype.addNewSingleArticleGroup = function (new_article_group) {
        var create_new = true;
        // for (let i = 0; i < this.article_group_list.length; i++) {
        //     if (this.article_group_list[i].article.id == new_article_group.article.id
        //         && this.article_group_list[i].size_index == new_article_group.size_index
        //         && JSON.stringify(this.article_group_list[i].added_ingredients) === JSON.stringify(new_article_group.added_ingredients)) {
        //         create_new = false;
        //         this.article_group_list[i].increaseAmount();
        //         break;
        //     }
        // }
        if (create_new) {
            this.article_group_list.push(new_article_group);
        }
        this.article_group_list_subject.next(this.article_group_list);
        // update pizza mega deal
        /**
         * @hardcoded
         */
        if (new_article_group.article.category_id === 39 || new_article_group.article.category_id === 196) {
            this.megaDealCounter[new_article_group.size_index]++;
            this.megaDealCounter.total++;
            this.megaDealCounterSubject.next(this.megaDealCounter);
        }
        /* put items in session storage */
        sessionStorage.setItem('cartItems', JSON.stringify(this.article_group_list));
        /* recalculate order */
        // this.recalculatePrices();
    };
    /**
     * Increment the amount of products in cart
     * @param index The index of the cart items array
     */
    CartService.prototype.increaseAmount = function (index) {
        // update pizza mega deal
        /**
         * @hardcoded
         */
        // if (this.article_group_list[index] && (this.article_group_list[index].article.category_id === 39 || this.article_group_list[index].article.category_id === 196)) {
        //     this.megaDealCounter[this.article_group_list[index].size_index]++;
        //     this.megaDealCounter.total++;
        //     this.megaDealCounterSubject.next(this.megaDealCounter);
        // }
        this.article_group_list[index].increaseAmount();
        sessionStorage.setItem('cartItems', JSON.stringify(this.article_group_list));
        // this.loadCartSessionStorage();
        this.recalculatePrices();
        this.article_group_list_subject.next(this.article_group_list);
        // update session storage
    };
    CartService.prototype.increaseAmountDoubled = function (index) {
        var tempGroup = JSON.parse(JSON.stringify(this.article_group_list[index]));
        this.addNewSingleArticleGroup(tempGroup);
        sessionStorage.setItem('cartItems', JSON.stringify(this.article_group_list));
        // this.loadCartSessionStorage();
        this.recalculatePrices();
        this.article_group_list_subject.next(this.article_group_list);
    };
    /**
     * Decrement the amount of products in cart
     * @param index The index of the cart items array
     */
    CartService.prototype.decreseAmount = function (index) {
        // update pizza mega deal
        /**
         * @hardcoded
         */
        // if (this.article_group_list[index] && (this.article_group_list[index].article.category_id === 39 || this.article_group_list[index].article.category_id === 196)) {
        //     this.megaDealCounter[this.article_group_list[index].size_index]--;
        //     this.megaDealCounter.total--;
        //     this.megaDealCounterSubject.next(this.megaDealCounter);
        // }
        if (this.article_group_list[index].amount <= 1) {
            delete this.article_group_list[index];
            this.article_group_list = this.resetIndexes(this.article_group_list);
        }
        else {
            this.article_group_list[index].decreaseAmount();
        }
        sessionStorage.setItem('cartItems', JSON.stringify(this.article_group_list));
        // this.loadCartSessionStorage();
        this.recalculatePrices();
        this.article_group_list_subject.next(this.article_group_list);
    };
    CartService.prototype.getEditArticle = function () {
        return JSON.parse(JSON.stringify(this.article_group_list[this.edit_index]));
    };
    CartService.prototype.submitEditArticle = function (price, current_size, added_ing, amountArt) {
        this.article_group_list[this.edit_index].prices = price;
        this.article_group_list[this.edit_index].size_index = current_size;
        this.article_group_list[this.edit_index].added_ingredients = added_ing;
        this.article_group_list[this.edit_index].amount = amountArt;
        // this.recalculatePrices();
        this.resetEditStat();
        // update session storage
        sessionStorage.setItem('cartItems', JSON.stringify(this.article_group_list));
        this.recalculatePrices();
    };
    CartService.prototype.resetEditStat = function () {
        this.on_edit = false;
    };
    /**
     * The the current tip value
     * @param new_tip The tip value
     */
    CartService.prototype.setTip = function (new_tip) {
        this.tip = new_tip;
        this.tipSubject.next(this.tip);
        sessionStorage.setItem('tip', this.tip.toString());
        this.recalculatePrices();
        this.article_group_list_subject.next(this.article_group_list);
    };
    /**
     * The the current tip value
     * @param new_tip The tip value
     */
    CartService.prototype.setTipFixedPercentage = function (percentage) {
        this.tipFixedPercentage = percentage;
        this.tipFixedPercentageSubject.next(this.tipFixedPercentage);
    };
    /**
     * Get the current tip value
     */
    CartService.prototype.getTip = function () {
        return this.tip;
    };
    CartService.prototype.resetIndexes = function (array) {
        var temp = [];
        for (var _i = 0, array_1 = array; _i < array_1.length; _i++) {
            var item = array_1[_i];
            if (item != undefined) {
                temp.push(item);
            }
        }
        return temp;
    };
    /**
     * Recalculate prices of the current order
     */
    CartService.prototype.recalculatePrices = function (checkPriceDiff) {
        var _this = this;
        if (checkPriceDiff === void 0) { checkPriceDiff = false; }
        return new Promise(function (resolve) {
            // const priceCartTemp = this.priceCart;
            // this.showPriceDifferencesPopup = false;
            // this.showPriceDifferencesPopupSubject.next(this.showPriceDifferencesPopup);
            /* calculate if mega deal is active */
            // this.calcMegaDeal();
            var amount = 0;
            var price = 0;
            var price_item = 0;
            _this.loadCartSessionStorage(false, checkPriceDiff);
            // for (let i = 0; i < this.article_group_list.length; i++) {
            //     const temp_group = this.article_group_list[i];
            //     let ing_price = 0;
            //     let ing_price_for_free_addables = 0;
            //     amount = amount + temp_group.amount;
            //     /* calculate free addabales and ingredients */
            //     for (let i = 0; i < temp_group.added_ingredients.length; i++) {
            //         temp_group.added_ingredients[i].ingredients['real_price'] = temp_group.added_ingredients[i].ingredients['price'][temp_group.size_index].price;
            //         const ing_price_single = (temp_group.added_ingredients[i].amount - temp_group.added_ingredients[i].free_amount) * temp_group.added_ingredients[i].ingredients['price'][temp_group.size_index].price;
            //         temp_group.added_ingredients[i].ingredients['real_price_total'] = ing_price_single;
            //         ing_price += ing_price_single;
            //     }
            //     for (let i = 0; i < temp_group.added_ingredients.length; i++) {
            //         if (this.onLunch && temp_group.added_ingredients[i].ingredients['price'][temp_group.size_index].is_free_addable) {
            //             ing_price_for_free_addables += 0;
            //         } else {
            //             ing_price_for_free_addables += temp_group.added_ingredients[i].free_amount * temp_group.added_ingredients[i].ingredients['price'][temp_group.size_index].price;
            //         }
            //     }
            //     temp_group.prices.price_for_added_ingredients = ing_price;
            //     temp_group.prices.price_for_added_free_addables = ing_price_for_free_addables;
            //     if (
            //         (
            //         this.megaDealCounter.active &&
            //         temp_group.prices.mega_deal_price > 0 &&
            //         (temp_group.article.category_id === 39 || temp_group.article.category_id === 196) &&
            //         !this.onLunch
            //         )
            //         ||
            //             (
            //                 this.megaDealCounter.active &&
            //                 temp_group.prices.mega_deal_price > 0 &&
            //                 (temp_group.article.category_id === 39 || temp_group.article.category_id === 196) &&
            //                 this.onLunch &&
            //                 temp_group.size_index === 1
            //             )
            //     ) {
            //         if (temp_group.prices.mega_deal_price > temp_group.prices.regular_price && !this.on_pickup) {
            //             temp_group.prices.real_price = temp_group.prices.regular_price;
            //             price_item = (temp_group.amount * (temp_group.prices.regular_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //             price = price + price_item;
            //         } else if (temp_group.prices.mega_deal_price > temp_group.prices.pickup_price && this.on_pickup) {
            //             temp_group.prices.real_price = temp_group.prices.pickup_price;
            //             price_item = (temp_group.amount * (temp_group.prices.pickup_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //             price = price + price_item;
            //         } else {
            //             temp_group.prices.real_price = temp_group.prices.mega_deal_price;
            //             price_item = (temp_group.amount * (temp_group.prices.mega_deal_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //             price = price + price_item;
            //         }
            //     } else {
            //         /* calculate the regular price for cart item */
            //         if ((!this.on_pickup && !this.on_happy_hour && !this.onLunch) || (!this.on_pickup && this.on_happy_hour && !temp_group.prices.happy_hour_price) || (!this.on_pickup && this.onLunch && !temp_group.prices.lunch_price)) {
            //             /* recalculate price if it is not lunch time and the article has enabled "only on lunch" flag */
            //             if (temp_group.prices.price_for_added_free_addables > 0) {
            //                 if (!temp_group.article.price[temp_group.size_index].always_show_free_addables) {
            //                     temp_group.prices.real_price = temp_group.prices.regular_price ;
            //                     price_item = (temp_group.amount * (temp_group.prices.regular_price * 1 + temp_group.prices.price_for_added_ingredients * 1 + temp_group.prices.price_for_added_free_addables * 1));
            //                     price = price + price_item;
            //                 } else {
            //                     temp_group.prices.real_price = temp_group.prices.regular_price ;
            //                     price_item = (temp_group.amount * (temp_group.prices.regular_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                     price = price + price_item;
            //                 }
            //             } else {
            //                 temp_group.prices.real_price = temp_group.prices.regular_price ;
            //                 price_item = (temp_group.amount * (temp_group.prices.regular_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                 price = price + price_item;
            //             }
            //         }
            //         /* calculate the pickup price for cart item */
            //         if ((this.on_pickup && !this.on_happy_hour && !this.onLunch) || (this.on_pickup && this.on_happy_hour && !temp_group.prices.happy_hour_price) || (this.on_pickup && this.onLunch && !temp_group.prices.lunch_price)) {
            //             /* recalculate price if it is not lunch time and the article has enabled "only on lunch" flag */
            //             if (temp_group.prices.price_for_added_free_addables > 0) {
            //                 if (!temp_group.article.price[temp_group.size_index].always_show_free_addables) {
            //                     temp_group.prices.real_price = temp_group.prices.pickup_price ;
            //                     price_item = (temp_group.amount * (temp_group.prices.pickup_price * 1 + temp_group.prices.price_for_added_ingredients * 1 + temp_group.prices.price_for_added_free_addables * 1));
            //                     price = price + price_item;
            //                 } else {
            //                     temp_group.prices.real_price = temp_group.prices.pickup_price ;
            //                      price_item = (temp_group.amount * (temp_group.prices.pickup_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                     price = price + price_item;
            //                 }
            //             } else {
            //                 temp_group.prices.real_price = temp_group.prices.pickup_price ;
            //                 price_item = (temp_group.amount * (temp_group.prices.pickup_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                 price = price + price_item;
            //             }
            //         }
            //         if (this.on_happy_hour && temp_group.prices.happy_hour_price) {
            //             temp_group.prices.real_price = temp_group.prices.happy_hour_price ;
            //             price_item = (temp_group.amount * (temp_group.prices.happy_hour_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //             price = price + price_item;
            //         }
            //         //Lunch logic
            //         if (this.onLunch && temp_group.prices.lunch_price) {
            //             if (!sessionStorage.getItem('masterstore_id')) {
            //                 sessionStorage.setItem('masterstore_id', this.frontendService.storeData.master);
            //             }
            //             if( this.on_pickup && temp_group.prices.pickup_price < temp_group.prices.lunch_price && temp_group.article.category_id !== 42 ) {
            //                 temp_group.prices.real_price = temp_group.prices.pickup_price;
            //                 price_item = (temp_group.amount * (temp_group.prices.real_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                 price = price + price_item;
            //             } else if (!this.on_pickup && temp_group.prices.real_price >= temp_group.prices.lunch_price && sessionStorage.getItem('masterstore_id') == '1') {
            //                 var pls = 0;
            //                 temp_group.prices.real_price =  temp_group.prices.lunch_price + pls;
            //                 price_item = (temp_group.amount * (temp_group.prices.real_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                 price = price + price_item;
            //             } else {
            //                 //IF NOT PICKUP AND PICKUP_PRICE > LUNCH_PRICE
            //                 temp_group.prices.real_price =  temp_group.prices.lunch_price;
            //                 price_item = (temp_group.amount * (temp_group.prices.real_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                 price = price + price_item;
            //             }
            //         }
            //     }
            //     temp_group.prices.real_price_total = price_item;
            // }
            // price = Math.round(price * 100) / 100;
            // this.article_amount = amount;
            // this.priceCart = price;
            /* no min value on pickup */
            // if (this.on_pickup) {
            //     this.min_val = 0;
            //     this.min_val_subject.next(this.min_val);
            // } else {
            //     this.min_val = this.minValueTemp;
            //     this.min_val_subject.next(this.min_val);
            // }
            // /* New additions moved from cart page - calculate the total price of the curret order */
            // if (this.addMinQuantitySurcharge === true) {
            //     this.priceTotal = this.priceCart + this.min_quan_sur/*  + this.tip */;
            // } else {
            //     this.priceTotal = this.priceCart/*  + this.tip */;
            // }
            // /* Add the local values to observables */
            // this.min_quan_sur = (this.min_val - this.priceCart) < 0 ? 0 : (this.min_val - this.priceCart);
            // this.min_quan_sur_subject.next(this.min_quan_sur);
            // this.priceCartSubject.next(this.priceCart);
            // this.priceTotalSubject.next(this.priceTotal);
            // this.article_amount_subject.next(this.article_amount);
            /* check differences */
            // if( this.showPriceDifferencesPopup === false ) {
            //     if (priceCartTemp !== 0) {
            //         if (priceCartTemp !== this.priceCart) {
            //             this.priceCartTemp = priceCartTemp;
            //             this.priceCartTempSubject.next(this.priceCartTemp);
            //             this.showPriceDifferencesPopup = true;
            //             this.showPriceDifferencesPopupSubject.next(this.showPriceDifferencesPopup);
            //         }
            //     }
            // }
            /* Resolve the promise at the end */
            resolve();
        });
    };
    /**
     * Reset all cart data to default
     */
    CartService.prototype.resetCartData = function () {
        this.article_group_list = [];
        this.min_quan_sur = 0;
        this.priceTotal = 0;
        this.priceCart = 0;
        this.article_amount = 0;
        // this.priceTotalRegular = 0;
        // this.priceTotalPickup = 0;
        //this.min_val = 0;
        //this.min_val_subject.next(this.min_val);
        this.priceTotalSubject.next(this.priceTotal);
        this.priceCartSubject.next(this.priceCart);
        this.article_amount_subject.next(this.article_amount);
        this.addMinQuantitySurcharge = false;
        this.addMinQuantitySurchargeSubject.next(this.addMinQuantitySurcharge);
        // this.priceTotalRegularSubject.next(this.priceTotalRegular);
        // this.priceTotalPickupSubject.next(this.priceTotalPickup);
        this.min_quan_sur_subject.next(this.min_val);
        this.article_group_list_subject.next(this.article_group_list);
        sessionStorage.setItem('cartItems', '[]');
    };
    CartService.prototype.getDelivDateTime = function () {
        if (!sessionStorage.getItem('deliveryDate') || sessionStorage.getItem('deliveryDate') == 'Invalid Date') {
            this.deliveryDate = this.dateService.newDate();
            sessionStorage.setItem('deliveryDate', this.deliveryDate.toString());
        }
        if (!this.deliveryDate || this.deliveryDate.toString() == 'Invalid Date') {
            this.deliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        }
        if (!sessionStorage.getItem('deliveryTime')) {
            if (this.deliveryLaterTimeValues.length > 0) {
                this.deliveryTime = this.deliveryLaterTimeValues[0].time;
            }
            else {
                this.deliveryTime = this.deliveryDate.toLocaleTimeString().substring(0, 5);
            }
            sessionStorage.setItem('deliveryTime', this.deliveryTime);
            this.deliveryTimeSubject.next(this.deliveryTime);
        }
        if (!this.deliveryTime) {
            this.deliveryTime = sessionStorage.getItem('deliveryTime');
        }
        this.deliveryDate.setHours(parseInt(this.deliveryTime.substring(0, 2)));
        this.deliveryDate.setMinutes(parseInt(this.deliveryTime.substring(3, 5)));
        this.deliveryDateSubject.next(this.deliveryDate);
    };
    CartService.prototype.getPickup = function () {
        return this.on_pickup;
    };
    CartService.prototype.getLunch = function () {
        return this.onLunch;
    };
    CartService.prototype.getdeliveryDate = function () {
        return this.deliveryDate;
    };
    CartService.prototype.getdeliveryTime = function () {
        return this.deliveryTime;
    };
    CartService.prototype.changeChangedTempArticles = function () {
        this.changedTempArticles++;
        this.changedTempArticlesSubject.next(this.changedTempArticles);
    };
    CartService.prototype.goToHome = function () {
        this.router.navigate(['/willkommen/start']).then(function () {
            sessionStorage.clear();
            localStorage.clear();
            document.location.reload();
        });
    };
    CartService.ngInjectableDef = i0.defineInjectable({ factory: function CartService_Factory() { return new CartService(i0.inject(i1.FrontendService), i0.inject(i2.Router), i0.inject(i3.DateService), i0.inject(i4.LeadtimeService)); }, token: CartService, providedIn: "root" });
    return CartService;
}());
export { CartService };
