

<!--<button class="log-button" (click)="onLog()">Log Step 1 Component</button>-->
<ng-container *ngIf="!ready">
    <div class="text-center color-primary">
        <svg-icon src="assets/icons/icon-loading.svg" [svgStyle]="{ 'fill':'currentColor' }"></svg-icon>
    </div>
</ng-container>

<ng-container *ngIf="ready">

    <div id="container-willkomen">
        <!-- begin: Wähle Deine Filiale-->
        <div id="filiale">
            <h4 class="welcome-step-1 text-center pb-0">Wähle deine Filiale</h4>
            <div class="welcome-step ">
                <div *ngIf="storeData?.id === 2"
                     class="row d-flex justify-content-center align-items-center button-holder">
                    <div class="col-6 px-0 d-flex justify-content-center align-items-center welcome-step-item-mp ">
                        <a *ngIf="getEnviorment == 'dev'" class="mp-btn-55 text-center"
                           href="http://pizza-dd.awag-it.de/#/willkommen/start">
                            Dresden
                        </a>
                        <a *ngIf="getEnviorment == 'stage'" class="mp-btn-55 text-center"
                           href="http://stage-dresden.awag-it.de/#/willkommen/start">
                            Dresden
                        </a>
                        <a *ngIf="getEnviorment == 'prod'" class="mp-btn-55 text-center"
                           href="https://dresden-meisterpizza.de/#/willkommen/start">
                            Dresden
                        </a>
                    </div>
                    <div class="col-6 px-0 d-flex justify-content-center align-items-center  welcome-step-item-mp">
                        <button id="fb" class="mp-btn-5 text-center" value="aktiv">Freiberg</button>
                    </div>
                </div>

                <div *ngIf="storeData?.id === 7"
                     class="row d-flex justify-content-center align-items-center button-holder">

                    <div class="col-6 px-0 d-flex justify-content-center align-items-center  welcome-step-item-mp">
                        <button id="dd" class="mp-btn-5 text-center" value="aktiv">Dresden</button>
                    </div>
                    <div class="col-6 px-0 d-flex justify-content-center align-items-center  welcome-step-item-mp">
                        <a *ngIf="getEnviorment == 'dev'" class="mp-btn-55 text-center"
                           href="http://pizza.awag-it.de/#/willkommen/start">
                            Freiberg
                        </a>

                        <a *ngIf="getEnviorment == 'stage'" class="mp-btn-55 text-center"
                           href="http://stage-freiberg.awag-it.de/#/willkommen/start">
                            Freiberg
                        </a>
                        <a *ngIf="getEnviorment == 'prod'" class="mp-btn-55 text-center"
                           href="https://freiberg-meisterpizza.de/#/willkommen/start">
                            Freiberg
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- end Wähle Deine Filiale -->
        <section id="datum_datum"></section>
        <!-- begin: welcome step 1    Lieferung-->
        <div id="liefern" class="step1-mp-container">
            <h4 class="welcome-step text-center pb-0">Liefern lassen oder selbst abholen?</h4>

            <div class="welcome-step" id="order">
                <div class="row d-flex justify-content-center align-items-center button-holder">
                    <div class="col-6 px-0 d-flex justify-content-center align-items-center  welcome-step-item-mp">
                        <button id="lassen" value="{{anmeldung.valueLiefern}}"
                                class="mp-btn-5 d-flex justify-content-center align-items-center justify-content-lg-between"
                                (click)="onChangePickup(false)">
                            <svg-icon class="svg-step5" src="/assets/icons/Auto.svg" class="show-desktop"></svg-icon>
                            Liefern lassen
                        </button>
                    </div>
                    <div class="col-6 px-0 d-flex justify-content-center align-items-center  welcome-step-item-mp">
                        <button id="selbst" value="{{anmeldung.valueHolen}}"
                                class="mp-btn-5 d-flex justify-content-center align-items-center justify-content-lg-between"
                                (click)="onChangePickup(true)">
                            <svg-icon class="svg-step5" src="assets/icons/icon_welcome_store.svg" class="show-desktop"></svg-icon>
                            Selbst abholen
                        </button>

                    </div>
                </div>

            </div>
            <!-- end: welcome step 1 -->

        </div>

        <!-- begin: welcome step 4 Adresse !editStep-->
        <!-- <div *ngIf="anmeldung.isLiefern" class="step4-mp-container">
            <div class="welcome-step">
                <div class="row m-0 justify-content-center form-input text-center">
                    <div class="col-12 p-0">
                        <h4>Bitte gib deine Lieferadresse ein.</h4>
                        <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <p class="mb-1"><span
                                    class="text-plz">PLZ nicht gefunden?&nbsp;Dann ruf uns unter&nbsp;</span></p>
                            <p class="mb-1"><a href="tel:{{ storeData.phone }}">{{ storeData.phone }}</a> &nbsp;<span
                                    class="text-plz">an!</span></p>
                        </div>
                    </div>

                    <div class="adresse">
                        <app-address-input></app-address-input>

                        <div class="container-pfeile" (click)="weiter()">
                            <div class="pfeile">
                                <svg id="drei">
                                    <polygon class="pfeil-top"
                                             points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
                                    <polygon class="pfeil-middle"
                                             points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
                                    <polygon class="pfeil-bottom"
                                             points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
                                </svg>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div> -->

        <!-- end: welcome step 4 -->

        <section id="datum"></section>
        <!-- begin: welcome step 3 Datum & Zeit-->
        <div *ngIf="anmeldung.isLiefern || anmeldung.isHolen" class="step3-mp-container">
            <div class="welcome-step ">
                <!--   <div class="row m-0 justify-content-center form-input text-center">-->
                <div class=" justify-content-center form-input text-center target-scroll-pikup">
                    <div class="col-12 p-0">
                        <h4 *ngIf="!shopClosed && pickup" class=" text-center">Wann möchtest du abholen?</h4>

                        <h4 *ngIf="!shopClosed && !pickup" class=" text-center mt-3">Wann sollen wir liefern?</h4>

                        <h4 *ngIf="shopClosed" class=" text-center"> Zu wann möchtest du bestellen?</h4>
                    </div>

                    <!-- <div *ngIf="isDelivDateNow && !pickup" -->
                    <div *ngIf="!shopClosed && !pickup"
                         class="spetsof row d-flex justify-content-center align-items-center button-holder mt-7 mb-7 ">

                        <div class="col-6 px-0 d-flex justify-content-center align-items-center  welcome-step-item-mp"
                             *ngIf="noMoreDeliveryLunch || noMoreDelivery">
                            <a (click)="showPopup_no_delivery_until_closed_popup_f(true, true)">
                                <button class="mp-btn-5 text-center btn-s" (click)="showFields()"
                                        [disabled]="!addressValid && !pickup">Später
                                </button>
                            </a>
                        </div>
                        <div class="col-6 px-0 d-flex justify-content-center align-items-center  welcome-step-item-mp"
                             *ngIf="!noMoreDeliveryLunch && !noMoreDelivery">
                            <a (click)="onSetDeliveryLater(true)">
                                <button *ngIf="!bttnVal" id="spt" class="mp-btn-5 text-center btn-s" (click)="showFields()"
                                        [disabled]="!addressValid && !pickup">Später
                                </button>
                                <button *ngIf="bttnVal" id="spt" class="mp-btn-5 text-center btn-s" (click)="showFields()"
                                        [disabled]="!addressValid && !pickup" value="aktiv">Später
                                </button>
                            </a>
                        </div>
                        <div class="col-6 px-0 d-flex justify-content-center align-items-center  welcome-step-item-mp">
                            <a (click)="showFields(false, true)">
                                <button
                                class="mp-btn-5 text-center btn-s"
                                [disabled]="!addressValid && !pickup">Sofort</button>
                            </a>
                        </div>

                    </div>
                    <!-- showFieldsV {{showFieldsV}} - shopClosed {{shopClosed}} - pickup {{pickup}} - addressValid {{addressValid}} - shopClosedUntilTomorrow {{shopClosedUntilTomorrow}} - timeValuesCreated {{timeValuesCreated}} -->
                    <div *ngIf="(!isDelivDateNow && anmeldung.edit) || showFieldsV || (shopClosed && pickup) || (!pickup && addressValid && (showFieldsV || shopClosed) || (isDelivDateNow && pickup))"
                         class="stpZt adresse d-flex flex-column justify-content-center ">
                        <!-- begin: select time -->
                        <div class="form-group-wrapper form-input mt-3 container-zeiten" style="position: relative;">

                            <label *ngIf="pickup && !(!deliveryLaterTimeValues || deliveryLaterTimeValues.length<=0)"
                                   class="bold label-5 text-left">Abholzeit</label>
                            <label *ngIf="!pickup && !(!deliveryLaterTimeValues || deliveryLaterTimeValues.length<=0)"
                                   class="bold label-5 text-left">Lieferzeit</label>

                            <!-- Zeit -->
                            <mat-form-field id="zeit" appearance="outline"
                                            *ngIf="!(!deliveryLaterTimeValues || deliveryLaterTimeValues.length<=0)">
                                <mat-select disableOptionCentering matNativeControl
                                            [disabled]="!deliveryLaterTimeValues || deliveryLaterTimeValues.length<=0 || !timeValuesCreated"
                                            [(ngModel)]="deliveryTime" required
                                            (selectionChange)="onChangeDeliveryTime()">
                                    <mat-option *ngFor="let option of deliveryLaterTimeValues, let i = index"
                                                [value]="option.time">
                                        {{ option.time }} Uhr
                                        <!-- <span *ngIf="next_delivery_later_time && storeData.master == 3 && i == 0"> (oder
                                            Schnellmöglichst)</span> -->
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>


                            <!-- Delivery Message -->
                            <div class="delivery-msg">
                                <!-- {{ pickup }}  {{ deliveryLaterTimeValues.length }} {{ onLunch }} -->
                                <div *ngIf="(!pickup && !(!deliveryLaterTimeValues || deliveryLaterTimeValues.length<=0)) && onLunch"
                                     class="pt-2">
                                    <p>Lieferzeit liegt innerhalb vom Mittagstisch</p>
                                </div>


                                <div *ngIf="(pickup && !(!deliveryLaterTimeValues || deliveryLaterTimeValues.length<=0)) && onLunch"
                                     class="pt-2">
                                    <p>Abholzeit liegt innerhalb vom Mittagstisch</p>
                                </div>

                                <!--   <div *ngIf="(!pickup && (deliveryLaterTimeValues || deliveryLaterTimeValues.length<=0)) && onLunch"
                                        class="pt-2">TEST Lieferzeit liegt innerhalb vom Mittagstisch</div>-->

                            </div>
                            <div *ngIf="!timeValuesCreated" class="color-primary icon-loading">
                                <svg-icon src="assets/icons/icon-loading.svg"
                                          [svgStyle]="{ 'fill':'currentColor' }"></svg-icon>
                            </div>


                        </div>
                        <!-- end: select time -->

                        <!-- begin: select delivery date -->
                        <div class="mt-3 form-group-wrapper form-input ">

                            <label *ngIf="pickup" class="bold label-5 text-left">Abholdatum</label>
                            <label *ngIf="!pickup" class="bold label-5 text-left">Lieferdatum</label>

                            <mat-form-field id="datum" appearance="outline">
                                <input (click)="picker.open()" matInput [min]="minDeliveryDate" [matDatepicker]="picker" [matDatepickerFilter]="dayFilter"
                                       (dateInput)="onChangeDeliveryDate($event.value)" [(ngModel)]="deliveryDate"
                                       name="delivery_date" readonly class="mat-form-field-input-5">
                                <mat-datepicker-toggle matSuffix [for]="picker" class="mat-datepicker-toggle-5">
                                </mat-datepicker-toggle>
                                <mat-datepicker [dateClass]="selectedDateClass" disabled="false" #picker class="mat-datepicker-5"></mat-datepicker>
                            </mat-form-field>


                            <!-- Warnung Message -->
                            <!-- <div *ngIf="cartService.closedInThisDate == 1 || deliveryLaterTimeValues.length<=0"
                                 class="bold"
                                 style="color:#ff0000; text-align: center;height: 100%;display: flex;align-items: flex-end;"> -->
                                 <div *ngIf="cartService.closedInThisDate == 1"
                                 class="bold"
                                 style="color:#ff0000; text-align: center;height: 100%;display: flex;align-items: flex-end;">
                                Wir haben am
                                {{deliveryDate.getDate()}}.{{deliveryDate.getMonth() + 1}}.{{deliveryDate.getFullYear()}}
                                geschlossen. Wähle bitte einen anderen Liefertag.
                            </div>

                        </div>


                    </div>


                </div>

            </div>


            <!--POPUP-->
            <!--from Step-3, for select delivery date-->


            <!-- <div class="bg-shop-closed"
            *ngIf="(showPopupNow && noMoreDelivery && showPopup_no_delivery_until_closed_popup && addressValid && !pickup) || (showPopupNow && noMoreDelivery && showPopup_no_delivery_until_closed_popup && pickup)"> -->

            <!--
            <div class="bg-shop-closed"
            *ngIf="(showPopupNow && noMoreDelivery && showPopup_no_delivery_until_closed_popup && addressValid && !pickup)">


                <div class="dialog_no_delivery_anymore close-soon">
                    <div class="title-dialog-shop-closed" *ngIf="pickup">
                        Keine Bestellung zu später heute mehr möglich.
                    </div>
                    <div class="title-dialog-shop-closed" *ngIf="!pickup">
                        Jetzt nur Sofort-Bestellung möglich! Alle Bestellungen bis {{next_closed_time}} Uhr werden heute geliefert.
                    </div>
                    <div class="col p-0">
                        <p class="mt-2" *ngIf="!pickup">
                            <svg-icon class="mb-4 d-block" src="assets/icons/icon_welcome_clock.svg"
                                      [svgStyle]="{ 'width.px':80,'fill':'var(--color-primary)' }"></svg-icon>
                            Bitte wähle
                        </p>
                        <p class="mt-2" (click)="showPopup_no_delivery_until_closed_popup_f(false)" *ngIf="!pickup">
                            <a  (click)="showFields(false, true)">
                                <button class="mp-btn mp-btn-yellow-on-black btn-pop-up">Sofort-Bestellung<br>bis {{next_closed_time}} Uhr
                                </button>
                            </a>

                        </p>
                        <p *ngIf="pickup">&nbsp;</p>
                        <p class="mt-2">
                            <button (click)="setDeliveryTomorrow(true)"
                            class="mp-btn mp-btn-yellow-on-black btn-pop-up">
                                Vorbestellung<br>
                                (zu morgen)
                            </button>
                        </p>
                    </div>
                </div>
            </div>
            -->

            <!--POPUP-->
            <!-- erscheint um 13:30 Uhr-->
            <!-- <div class="bg-shop-closed"
                 *ngIf="(noMoreDeliveryLunch && showPopup_no_delivery_until_closed_popup && addressValid && !pickup && showPopupNow) || (noMoreDeliveryLunch && showPopup_no_delivery_until_closed_popup && pickup && showPopupNow)"> -->
            <!-- <div class="bg-shop-closed" *ngIf="(noMoreDeliveryLunch && showPopup_no_delivery_until_closed_popup && !pickup && showPopupNow)">
                <div class="dialog_no_delivery_anymore close-soon">
                    <div class="title-dialog-shop-closed">
                        <p class="text-center text-uppercase">Zur Zeit nur Sofort-Bestellung möglich.</p>
                    </div>
                    <div class="col p-0">

                        <p class="m-2">
                            <svg-icon class="mb-4 d-block" src="assets/icons/icon_welcome_clock.svg"
                                      [svgStyle]="{ 'width.px':80,'fill':'var(--color-primary)' }"></svg-icon>
                            Bitte wähle
                        </p>
                        <p>
                            <a (click)="showFields(false, true)">
                                <button class="mp-btn mp-btn-yellow-on-black btn-pop-up" [disabled]="!addressValid && !pickup">Sofort-Bestellung<br> bis {{next_closed_time}} Uhr
                                </button>
                            </a>
                        </p>
                        <p>
                            oder
                        </p>
                        <p (click)="showPopup_no_delivery_until_closed_popup_f(false)">
                            <a (click)="onSetDeliveryLater(true)">
                                <button class="mp-btn mp-btn-yellow-on-black btn-pop-up" (click)="showFields()"
                                        [disabled]="!addressValid && !pickup">Vorbestellung zu heute <br>ab
                                    {{no_delivery_until_closed_next_time}} Uhr
                                </button>
                            </a>
                        </p>
                    </div>
                </div>
            </div> -->
        </div>


        <!-- Zum Warenkorb -->
        <div *ngIf="anmeldung.showBtnWarenkorb && (anmeldung.isLiefern || anmeldung.isHolen)" class=" mt-7 mb-5">
            <div class="pt-3 pt-lg-5 d-flex justify-content-center align-items-center button-holder">
                <button (click)="onSetDeliveryLater(true)" routerLink="/shop/bestellen/warenkorb"
                        [disabled]="anmeldung.disableWarenkorb || cartService.closedInThisDate == 1"
                        class="mp-btn-5 warenkorb" value="aktiv">Zum Warenkorb
                </button>

            </div>
        </div>

        <!-- jetzt bestellen-->
        <ng-container *ngIf="(!isDelivDateNow && anmeldung.edit) || showFieldsV || (shopClosed && pickup) || (!pickup && addressValid && (showFieldsV || shopClosed) || (isDelivDateNow && pickup))">
            <div *ngIf="((anmeldung.showBtnBestellen || !anmeldung.showBtnWarenkorb) && (anmeldung.isLiefern || anmeldung.isHolen))" class="mt-7 mb-5">
                <div class="pt-3 pt-lg-5 d-flex justify-content-center align-items-center button-holder">
                    <button routerLink="/shop"
                    [disabled]="(!pickup && !addressValid) || (!showFieldsV && !shopClosed) || cartService.closedInThisDate == 1 || !timeValuesCreated"
                            class="mp-btn-5" value="aktiv" (click)="(!deliveryLaterTimeValues || deliveryLaterTimeValues.length<=0)?showFields(false, true):onSetDeliveryLater(true)">
                            <span *ngIf="(deliveryLaterTimeValues && deliveryLaterTimeValues.length>0)">Weiter</span>
                            <span *ngIf="(!deliveryLaterTimeValues || deliveryLaterTimeValues.length<=0)">Sofort</span>
                    </button>
                </div>
                <!-- <div *ngIf="(!deliveryLaterTimeValues || deliveryLaterTimeValues.length<=0)" class="mt-7 mb-5">
                    <div class="pt-3 pt-lg-5 d-flex justify-content-center align-items-center button-holder">
                        <a (click)="showFields(false, true)">
                            <button class="mp-btn-5 text-center btn-s">Sofort</button>
                        </a>
                    </div>
                </div> -->
            </div>
        </ng-container>
    </div>
</ng-container>

<!-- begin: show price differences von Step-2-->
<!-- von STEP 2 -->
<!-- <ng-container *ngIf="showPriceDifferencesPopup">

    <div class="bg-shop-closed">
        <div class="dialog-shop-closed">
            <div class="title-dialog-shop-closed">
                Änderung der Bestellzeit von Step 2
            </div>
            <div class="cont-dialog-shop-closed">
                <p>Die Änderung deiner Bestellzeit <strong>{{ deliveryDate | date:'fullDate':'':'de' }}</strong>
                    <strong> {{ deliveryTime }}</strong> ändert den Gesamtbetrag deiner Bestellung um.
                </p>
                <p style="font-size: 18px;"><strong>Alter Preis: {{ priceCartTemp }}</strong></p>
                <p style="font-size: 22px;"><strong>Neuer Preis: {{ priceCart }}</strong></p>
                <div class="btn-dialog-shop-closed">
                    <button (click)="confirmPriceDifference()" class="mp-btn mp-btn-yellow-on-white">weiter 2</button>
                </div>
            </div>
        </div>
    </div>
</ng-container> -->
<!-- end: show price differences -->

<!-- begin: show price differences -->
<!-- von STEP 3 -->
<!-- <ng-container *ngIf="showPriceDifferencesPopupOpened === true">
    <div class="bg-shop-closed">
        <div class="dialog-shop-closed">
            <div class="title-dialog-shop-closed">
                Änderung der Bestellzeit von Step 3
            </div>
            <div class="cont-dialog-shop-closed">
                <p>Die Änderung deiner Bestellzeit <strong>{{ deliveryDate | date:'fullDate':'':'de' }}</strong> <strong> {{ deliveryTime }}</strong> ändert den Gesamtbetrag deiner Bestellung um.</p>
                <p style="font-size: 18px;"><strong>Alter Preis: {{ priceCartTemp }}</strong></p>
                <p style="font-size: 22px;"><strong>Neuer Preis: {{ priceCart }}</strong></p>
                <div class="btn-dialog-shop-closed">
                    <button (click)="showPriceDifferencesPopup = false;showPriceDifferencesPopupOpened=false" class="mp-btn mp-btn-yellow-on-black">weiter 3</button>
                </div>
            </div>
        </div>
    </div> -->
<!-- </ng-container> -->
<!-- end: show price differences -->
