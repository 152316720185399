<ng-container *ngIf="!ready">
    <div class="text-center color-primary centered-wrapper">
        <div class="all-centered">
            <div class="content-centered">
                <svg-icon src="assets/icons/icon-loading.svg" [svgStyle]="{ 'fill':'currentColor' }"></svg-icon>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="ready">
    <!--PopUp Abfrage Weitershoppen oder Warenkorb-->
    <div [class.clickwk]="show_dialog" class="go-cart-dialog">
        <div>
            <img class="icon-check" src="assets/img/icons/check.svg" alt="">
            <div class="go-cart-message">Du hast den Artikel erfolgreich in deinen Einkaufskorb gelegt.</div>

            <!--0: weitershoppen; 1: zum Wagen-->
            <div class="go-cart-btns">
                <div class="mt-4">
                    <button class="btn btn-shoppen" *ngIf="current_view == 1" (click)="onAddToCart(0)">Weiter bestellen
                    </button>
                </div>
                <div class="my-4">
                    <button class="btn btn-go-cart" *ngIf="current_view == 1" (click)="onAddToCart(1)">Zum Warenkorb
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="ready">
    <div class="article-box-row row margin">
        <!--Artikelbild + Größenauwahl / Spalte links-->
        <!--article-box-->
        <div class="col-md-12 article-box product-subcat-id-{{ sub_cat_id }} pageid-{{ currentPageId }}">
            <div class="d-flex flex-column bg-black-dark-desktop h-100">
                <!--Artikelname + Beschreibung-->
                <div class="article-box-heading heading">
                    <h3 class="mp-font-large-yellow">{{current_article.name}}</h3>
                    <p *ngIf="current_article.description" class="text-white">
                        <span [innerHTML]="current_article.description"></span>
                    </p>
                    <p *ngIf="!current_article.description" class="text-white">
                        <ng-container *ngFor="let ing of current_article.ingredients, let last = last">
                            {{ing.name}}
                            <ng-container *ngFor="let marking of ing.markings, let last = last">
                                <sup>{{marking.abbreviation}}</sup>
                                <ng-container *ngIf="!last"><sup>,</sup></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!last">,</ng-container>
                        </ng-container>
                    </p>
                    <!-- description lunch -->
                    <ng-container *ngIf="onLunch">
                        <ng-container *ngFor="let price of current_article.price, let i_price = index">
                            <ng-container *ngIf="price.description_lunch">
                                    <span *ngIf="current_size == i_price" class="description-lunch">
                                        {{ price.description_lunch }}
                                    </span>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <!-- description lunch -->
                    <p></p>
                    <!-- description permanent -->
                    <ng-container *ngFor="let price of current_article.price, let i_price = index">
                        <ng-container *ngIf="price.description_anytime">
                                <span *ngIf="current_size == i_price" class="description-lunch">
                                    {{ price.description_anytime }}
                                </span>
                        </ng-container>
                    </ng-container>
                    <!-- description permanent -->
                </div>

                <div class="article-box-content">
                    <!--Artikelbild-->
                    <!-- <picture class="desktop-flex"
                            [style.backgroundImage]="current_article.bgimage_detail ? 'url('+image_url + '/admin' + current_article.bgimage_detail+')' : 'url(/./assets/img/produkte/bg_article_box_620.jpg)' ">
                        <source media="(min-width: 48em)" srcset="{{image_url}}{{current_article.image.webp[6].image}}"
                                type="image/webp">
                        <source media="(min-width: 48em)" srcset="{{image_url}}{{current_article.image.png[6].image}}">
                        <source media="(min-width: 36em)" srcset="{{image_url}}{{current_article.image.webp[5].image}}"
                                type="image/webp">
                        <source media="(min-width: 36em)" srcset="{{image_url}}{{current_article.image.png[5].image}}">
                        <source media="(min-width: 30em)" srcset="{{image_url}}{{current_article.image.webp[3].image}}"
                                type="image/webp">
                        <source media="(min-width: 30em)" srcset="{{image_url}}{{current_article.image.png[3].image}}">
                        <source media="(min-width: 22.5em)"
                                srcset="{{image_url}}{{current_article.image.webp[1].image}}" type="image/webp">
                        <source media="(min-width: 22.5em)"
                                srcset="{{image_url}}{{current_article.image.png[1].image}}">
                        <source srcset="{{image_url}}{{current_article.image.webp[0].image}}" type="image/webp">
                        <source srcset="{{image_url}}{{current_article.image.png[0].image}}">
                        <img class="img-fluid mx-auto mh-100" src="{{image_url}}{{current_article.image.webp[6].image}}"
                             alt="">
                        <span class="infobox-link" (click)="onOpenInfoBox(current_article.id)">
                            <svg-icon src="assets/icons/icon_info-circle-solid-orange.svg"
                                      [svgStyle]="{ 'width.px':20,'fill':'currentColor' }"></svg-icon>
                        </span>
                    </picture> -->

                    <div id="article_steps" class="bg-black-dark-desktop w-100 d-flex flex-row justify-content-center">
                        <div class="mb-2 d-flex flex-row justify-content-center w-100">  

                            <div *ngIf="stepper_ready" class="d-flex flex-row flex-nowrap justify-content-center w-100">                                
                                <div *ngFor="let step of steps" class="step-block position-relative {{ step.class }} steps-{{steps.length}}" >
                                    <div class="stepper-horizontal-line-left"></div>
                                    <div class="stepper-horizontal-line-right"></div>
                                    <div class="step-header {{ ((!minFreeAddablesReached && step.stepIndex !== -1) || (!subAddablesReached && step.stepIndex > 0) || step.stepIndex > 1) ? 'not-allowed' : 'pointer' }}" 
                                        (click)="((!minFreeAddablesReached && step.stepIndex !== -1) || (!subAddablesReached && step.stepIndex > 0)) ? null : onStepContinue(step.stepIndex)">                                        
                                        <div class="step-icon">
                                            <div class="step-icon-content">
                                                <span>{{step.index}}</span>
                                            </div>
                                        </div>
                                        <div class="step-label">
                                            <div [innerHTML]="step.label"></div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>                            
                        </div>
                    </div>

                    <div class="article-box-cta bg-black-dark">
                        <!--Größenauswahl - aufgeteilt nach Anzahl der Gesamtgrößen-->
                        <!-- <ng-container *ngIf="current_article.price.length == 1">
                            <ng-container *ngFor="let price of current_article.price, let i_price = index">
                                <button (click)="selectSize(i_price)" [class.active]="current_size == i_price" class="">
                                    <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                    {{price.size}}
                                </button>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="current_article.price.length == 2">
                            <ng-container *ngFor="let price of current_article.price, let i_price = index">
                                <button (click)="selectSize(i_price)" [class.active]="current_size == i_price" class="">
                                    <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                    {{price.size}}
                                </button>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="current_article.price.length == 3">
                            <ng-container *ngFor="let price of current_article.price, let i_price = index">
                                <button (click)="selectSize(i_price)" [class.active]="current_size == i_price" class="">
                                    <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                    {{price.size}}
                                </button>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="current_article.price.length == 4">
                            <ng-container *ngFor="let price of current_article.price, let i_price = index">
                                <button (click)="selectSize(i_price)" [class.active]="current_size == i_price" class="">
                                    <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                    {{price.size}}
                                </button>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="current_article.price.length == 5">
                            <ng-container *ngFor="let price of current_article.price, let i_price = index">
                                <button (click)="selectSize(i_price)" [class.active]="current_size == i_price" class="">
                                    <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                    {{price.size}}
                                </button>
                            </ng-container>
                        </ng-container> -->
                    </div>
                </div>
            </div>

            <!--Section mit der Artikelname + Beschreibung + Größenauswahl - aufgeteilt nach Anzahl der Gesamtgrößen-->
            <!--<div class="h-100 p-0 col-lg-6 d-flex flex-column justify-content-center align-items-center container-details-product">

                <div class=" article-box-heading heading d-flex flex-column justify-content-center align-items-center">
                    <h3 class="product-title text-center">{{current_article.name}}</h3>
                    <p *ngIf="current_article.description" class="text-white text-center">
                        <span [innerHTML]="current_article.description"></span>
                    </p>
                    <p *ngIf="!current_article.description" class="text-white text-center">
                        <ng-container *ngFor="let ing of current_article.ingredients, let last = last">
                            {{ing.name}}
                            <ng-container *ngFor="let marking of ing.markings, let last = last">
                                <sup>{{marking.abbreviation}}</sup>
                                <ng-container *ngIf="!last"><sup>,</sup></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!last">,</ng-container>
                        </ng-container>
                    </p>
                    &lt;!&ndash; description lunch &ndash;&gt;
                    <ng-container *ngIf="onLunch">
                        <ng-container *ngFor="let price of current_article.price, let i_price = index">
                            <ng-container *ngIf="price.description_lunch">
                                <span *ngIf="current_size == i_price" class="description-lunch">
                                    {{ price.description_lunch }}
                                </span>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    &lt;!&ndash; /description lunch &ndash;&gt;
                </div>

                &lt;!&ndash;Größenauswahl - aufgeteilt nach Anzahl der Gesamtgrößen&ndash;&gt;
                <div class="article-box-cta mb-4">
                    <ng-container *ngIf="current_article.price.length == 1">
                        <ng-container *ngFor="let price of current_article.price, let i_price = index">
                            <button (click)="selectSize(i_price)" [class.active]="current_size == i_price" class="product-size">
                                <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                {{price.size}}
                            </button>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="current_article.price.length == 2">
                        <ng-container *ngFor="let price of current_article.price, let i_price = index">
                            <button (click)="selectSize(i_price)" [class.active]="current_size == i_price" class="product-size">
                                <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                {{price.size}}
                            </button>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="current_article.price.length == 3">
                        <ng-container *ngFor="let price of current_article.price, let i_price = index">
                            <button (click)="selectSize(i_price)" [class.active]="current_size == i_price" class="product-size">
                                <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                {{price.size}}
                            </button>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="current_article.price.length == 4">
                        <ng-container *ngFor="let price of current_article.price, let i_price = index">
                            <button (click)="selectSize(i_price)" [class.active]="current_size == i_price" class="product-size">
                                <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                {{price.size}}
                            </button>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="current_article.price.length == 5">
                        <ng-container *ngFor="let price of current_article.price, let i_price = index">
                            <button (click)="selectSize(i_price)" [class.active]="current_size == i_price" class="product-size">
                                <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                {{price.size}}
                            </button>
                        </ng-container>
                    </ng-container>
                </div>

                &lt;!&ndash;aktueller preis&ndash;&gt;
                <h3 class="article-price mb-4">{{current_price | number:
                    '1.2-5'}}&nbsp;€
                </h3>
            </div>-->
        </div>
        <div class="col-12 article-box">
            <div class="bg-black pb-0 clearfix">
                <!--aktueller preis-->


                <!--Änderungen 6 SEPTEMBER-->
                <div class="bg-white d-table" id="ingredient-box-0">
                    <!--Zutatenliste-->
                                        <ng-container>
                        <div class="ingred-main-article px-0 mb-1">
                            <div class="col-12 col-md-10 col-lg-8 col-xl-10 col-xxl-6 my-md-0 mx-md-auto d-flex flex-nowrap p-0 holder">
                                <div class="w-100 table-ingred  row mx-0 mb-2">
                                    <div class="grundpreis desktop-flex justify-content-between text-left ingred-list col-8 col-sm-10 d-flex pt-2 pb-1 pl-2 pr-4 mx-0">
                                        <div class="px-0 col-8 col-sm-10 d-flex">Grundpreis: {{current_article.name}} </div>
                                        <div class="d-flex align-items-center" *ngIf="price.regular_price > 0">{{ price.regular_price | number: '1.2-5'}}&nbsp;€</div>
                                        <div class="d-flex align-items-center" *ngIf="price.regular_price <= 0">gratis</div>
                                    </div>

                                    <!-- mobile -->
                                    <div class="grundpreis mobile-flex text-left ingred-list row col-8 col-md-10 d-flex py-1 pl-2 pr-0 mx-0">
                                        Grundpreis:
                                    </div>
                                    <div class="grundpreis mobile-flex text-left justify-content-between ingred-list row col-8 col-md-10 d-flex py-1 pl-2 pr-1 mx-0">
                                        <div class="px-0 col-8 col-sm-10 d-flex flex-row">
                                            <!-- <div>
                                                <span>{{ articleAmmount }}</span>&nbsp;x&nbsp;
                                            </div> -->
                                            <div>
                                                <span>{{current_article.name}} ({{ current_article.price[current_size].size }})</span>
                                            </div>
                                            <!-- <br> Größe: {{ current_article.price[current_size].size }} -->
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="price.regular_price > 0">{{ price.regular_price | number: '1.2-5'}}&nbsp;€</div>
                                        <div class="d-flex align-items-center" *ngIf="price.regular_price <= 0">gratis</div>
                                    </div>
                                    <!-- end mobile -->

                                    <div class="btn-container col-4 col-md-2 d-flex px-0 py-1 align-items-center justify-content-end ">
                                        <div class="btn-ingred-left ml-2">
                                            <button type="button" class="mp-btn-minus" (click)="onSubArt()"
                                                    [disabled]="articleAmmount<2 || !ingredientListLoaded">
                                                <svg-icon src="assets/icons/icon_minus.svg"
                                                          [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                            </button>
                                        </div>
                                        <div class="ingred-list mx-2 text-center">
                                            <span class="">{{ articleAmmount }}</span>
                                        </div>
                                        <div class="btn-ingred-right mr-2">
                                            <button type="button" class="mp-btn-plus" (click)="onAddArt()"
                                                    [disabled]="!ingredientListLoaded || current_article.is_coupon_product">
                                                <svg-icon src="assets/icons/icon_plus.svg"
                                                          [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-10 col-lg-8 col-xl-10 col-xxl-6 my-md-0 mx-md-auto p-0 holder">

                            <!-- <div *ngIf="addSubIngStart" class="text-center" style="margin: 50px 0;">
                                <img src="assets/img/preloader_onwhite.gif" alt="Preloader">
                            </div> -->
                            <div *ngIf="!addSubIngStart" class="article-detail-ingred-wrapper">
                                <div *ngFor="let ing of added_ing_list, let i = index" class="article-detail-ingred border-top pt-2">
                                    <ng-container *ngIf="added_ing_list[i]">
                                        <ng-container *ngIf="added_ing_list[i].free_amount > 0">
                                            <div class="table-ingred  row mx-0 mb-2">
                                                <div class="ingred-list col-8 col-md-10 d-flex justify-content-between py-1 pl-2 pr-1 pr-md-4 row mx-0">
                                                    <div class="px-0 col-8 col-sm-10 d-flex">
                                                        <!-- <div>
                                                            <span>{{added_ing_list[i].free_amount}}</span>&#160;x&#160;
                                                        </div> -->
                                                        <div>
                                                            <span> {{added_ing_list[i].ingredients.name}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center">
                                                        <!--Gesamtpreis-->
                                                        <ng-container>
                                                            <p class="my-0 px-0 col-4 col-sm-2">
                                                                Gratis
                                                            </p>
                                                        </ng-container>
                                                    </div>
                                                </div>

                                                <div class="btn-container col-4 col-md-2 d-flex px-0 py-1 align-items-center justify-content-end ">
                                                    <div class="btn-ingred-left ml-2">
                                                        <button type="button" class="mp-btn-minus" (click)="onSubIng(i)"
                                                                [disabled]="subIngIsDisabled(added_ing_list[i].ing_cat_index, added_ing_list[i].ing_index) || !ingredientListLoaded">
                                                            <svg-icon src="assets/icons/icon_minus.svg"
                                                                      [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                                        </button>
                                                    </div>
                                                    <div class="ingred-list mx-2 text-center">
                                                        <span class="">{{added_ing_list[i].free_amount}}</span>
                                                    </div>
                                                    <div class="btn-ingred-right mr-2">
                                                        <button type="button" class="mp-btn-plus" (click)="onAddIng(i)"
                                                                [disabled]="added_ing_list[i].addDisabledTopList || addIngIsDisabled(added_ing_list[i].ing_cat_index, added_ing_list[i].ing_index) || !ingredientListLoaded">
                                                            <svg-icon src="assets/icons/icon_plus.svg"
                                                                      [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>

                                        <ng-container
                                                *ngIf="added_ing_list[i].amount - added_ing_list[i].free_amount > 0">
                                            <div class="table-ingred row mx-0 mb-2">
                                                <div class="ingred-list col-8 col-md-10 d-flex justify-content-between py-1 pl-2 pr-1 pr-md-4 row mx-0">
                                                    <div class="px-0 col-8 col-sm-10 d-flex">
                                                        <!-- <div>
                                                            <span>{{added_ing_list[i].amount - added_ing_list[i].free_amount}}</span>&#160;x&#160;
                                                        </div> -->
                                                        <div>
                                                            <span> {{added_ing_list[i].ingredients.name}}</span>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center">
                                                        <!--Gesamtpreis-->
                                                        <ng-container>
                                                            <p class="my-0 px-0 col-4 col-sm-2"
                                                               *ngIf="price_ing_sum[i] == 0">
                                                                Gratis
                                                            </p>
                                                            <p class="my-0 px-0 col-4 col-sm-2"
                                                               *ngIf="price_ing_sum[i] != 0">
                                                                <!-- {{price_ing_sum[i] | number: '1.2-5'}}&nbsp;€ -->
                                                                {{added_ing_list[i].ingredients.price[current_size].price | number: '1.2-5'}}&nbsp;€
                                                            </p>
                                                        </ng-container>
                                                    </div>
                                                </div>

                                                <div class="btn-container col-4 col-md-2 d-flex px-0 py-1 align-items-center justify-content-end ">
                                                    <div class="btn-ingred-left ml-2">
                                                        <button type="button" class="mp-btn-minus" (click)="onSubIng(i)"
                                                                [disabled]="subIngIsDisabled(added_ing_list[i].ing_cat_index, added_ing_list[i].ing_index) || !ingredientListLoaded">
                                                            <svg-icon src="assets/icons/icon_minus.svg"
                                                                      [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                                        </button>
                                                    </div>
                                                    <div class="ingred-list mx-2 text-center">
                                                        <span class="">{{added_ing_list[i].amount - added_ing_list[i].free_amount}}</span>
                                                    </div>
                                                    <div class="btn-ingred-right mr-2">
                                                        <button type="button" class="mp-btn-plus" (click)="onAddIng(i)"
                                                                [disabled]="added_ing_list[i].addDisabledTopList || addIngIsDisabled(added_ing_list[i].ing_cat_index, added_ing_list[i].ing_index) || !ingredientListLoaded">
                                                            <svg-icon src="assets/icons/icon_plus.svg"
                                                                      [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>

                            <ng-container *ngIf="tempAddedArticles.length > 0">
                                <div class="article-detail-ingred-wrapper border-top pt-3">
                                    <p class="text-center"><strong>Extra Artikel</strong></p>
                                    <div *ngFor="let article of tempAddedArticles, let index = index"
                                         class="article-detail-ingred border-top pt-2">
                                        <div class="table-ingred row mx-0 mb-2">
                                            <div class="ingred-list col-8 col-md-10 d-flex p-1 pl-2 d-flex justify-content-between ">
                                                <div class="px-0 col-8 col-sm-10 d-flex">
                                                    <div [innerHTML]="article.name">
                                                    </div>
                                                    <div></div>
                                                </div>

                                                <div class="my-0 px-0 pr-md-4 col-4 col-sm-2 d-flex d-flex align-items-center justify-content-end">
                                                    {{ article.price[0].price | number: '1.2-5' }}&nbsp;€
                                                </div>
                                            </div>

                                            <div class="btn-container col-4 col-md-2 d-flex px-0 py-1 align-items-center justify-content-end ">
                                                <div class="btn-ingred-left ml-2">
                                                    <button type="button" class="mp-btn-minus"
                                                            (click)="onTempRemoveArticle(index)">
                                                        <svg-icon src="assets/icons/icon_minus.svg"
                                                                  [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                                    </button>
                                                </div>
                                                <div class="ingred-list mx-2 text-center">
                                                    <span class="">1</span>
                                                </div>
                                                <div class="btn-ingred-right mr-2">
                                                    <button type="button" class="mp-btn-plus"
                                                            (click)="onTempAddArticle(article, index)">
                                                        <svg-icon src="assets/icons/icon_plus.svg"
                                                                  [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="d-flex justify-content-between px-2 py-3 font-weight-bold border-top">
                                <span>Gesamtbetrag:</span>
                                <span class="ml-2">{{ current_price + tempAddedArticlesSumm  | number: '1.2-5' }} €</span>                                 
                            </div>
                            
                        </div>
                    </ng-container>

                    <!-- INCLUDE FREE ADDABLES VIEW WHEN PRODUCT GROUP HAS FREE ADDABLES -->
                    <!--<ng-container
                            *ngIf="current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch">
                        <app-add-free-ingredients
                                *ngIf="current_view == -1 && current_article.price[current_size].has_free_addables"
                                [sub_cat_id]="sub_cat_id" [ingredient_list]="ingredient_list"
                                [current_size_index]="current_size" [added_ing_list]="added_ing_list"
                                [price_ing_sum]="price_ing_sum" [amount]="amount" [onLunch]="onLunch"
                                [potatoAdded]="potatoAdded" [show_tab_of_free_addables]="show_tab_of_free_addables"
                                [ingredientListLoaded]="ingredientListLoaded" (changed_ing_emitter)="recalculatePrice()"
                                (sub_ing_emitter)="onSubIng($event)" (add_ing_emitter)="onAddIng($event)">
                        </app-add-free-ingredients>
                    </ng-container>-->
                </div>
                <!--/Änderungen 6 SEPTEMBER-->

                <div id="target-oben" class="article-price-wrap text-center my-2 target-oben">
                      <span
                              *ngIf="((current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch) && minFreeAddablesReached == false) || !showBtnNextStp || !subAddablesReached"
                               class="text-danger">Du hast noch nicht alle Gratiszutaten gewählt
                      </span>
                </div>

                <!-- DREI BUTTONS -->
                <div id="bnts-addabls-nav"
                        class="article-price-wrap d-flex flex-wrap justify-content-around align-items-center col-md-10 mt-md-3 mb-md-2 mb-xxl-3 col-lg-8 col-xl-10 col-xxl-8 col-xxxl-6">

                        <div class="zurueck  zurueck-top d-flex justify-content-center align-items-center">
                            <div class="article-btn-left">
                                <button
                                        *ngIf="current_view == -1 || 
                                        (current_view == 0 && !articleHasFreeIngridients) || 
                                        (current_view == 1 && !articleHasIngridients)"
                                        (click)="router.navigate(['shop'])" type="button" class="mp-btn-prev p-0">
                                    <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                </button>
                                <!--Conditions 1-->
                                <button
                                        *ngIf="current_view == 0 && current_article.price[current_size].has_free_addables && (current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch)"
                                        (click)="onStepContinue(-1)" type="button" class="mp-btn-prev p-0">
                                    <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                </button>
    
                                <!--Conditions 2-->
                                <button *ngIf="current_view == 1 && hasFreeIng" (click)="onStepContinue(0)"
                                        type="button" class="mp-btn-prev p-0">
                                    <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                </button>
    
                                <!--Conditions 3-->
                                <button
                                        *ngIf="current_view == 1 && !hasFreeIng && current_article.price[current_size].has_free_addables && (current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch)"
                                        (click)="onStepContinue(-1)" type="button" class="mp-btn-prev p-0">
                                    <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                </button>
    
                            </div>
                        </div>

                    <div class=" contaner-wahl-weiter py-1 d-flex flex-wrap justify-content-around align-items-center">

                        <div class="weiter wahl-top mx-auto">
                            <!-- <div class="added-to-cart added-to-cart-hide" [innerHTML]="addedToCartMessage"> -->
                            <div class="added-to-cart added-to-cart-hide">
                                <div class="bg-shop-closed">
                                    <div class="dialog-added">
                                        <p class="added_header font-weight-normal text-center">
                                            Deine Artikel wurden in den Warenkorb gelegt.
                                        </p>
                                        <div class="col p-0">
                                            <p class="added_text text-center">Möchtest du zur Kasse oder weiter einkaufen?</p>
                                            <p class="d-flex justify-content-between font-weight-bolder">
                                                <button class="text-uppercase mp-btn-narrow-sm_pad mp-btn button-black" routerLink="/shop">Weiter einkaufen</button>
                                                <button class="text-uppercase mp-btn-narrow-sm_pad mp-btn mp-btn-yellow-on-white" routerLink="/shop/bestellen/warenkorb">Zur Kasse</button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Button weiter/WK-->
                            <div class="article-btn-right d-flex flex-wrap justify-content-center align-items-center">

                                <button *ngIf="current_view == -1 && hasFreeIng"
                                        (click)="onStepContinue(0)"
                                        [disabled]="(current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch) && minFreeAddablesReached == false"
                                        type="button" class="mp-btn mp-btn-yellow-on-black weiter  mp-btn-rd-upercased">Weiter
                                </button>

                                <button *ngIf="current_view == -1 && !hasFreeIng"
                                        (click)="onStepContinue(1)"
                                        [disabled]="(current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch) && minFreeAddablesReached == false"
                                        type="button" class="mp-btn mp-btn-yellow-on-black weiter mp-btn-rd-upercased">Weiter
                                </button>

                                <!-- ***v*** -->
                                <button *ngIf="current_view == 0" (click)="onStepContinue(1)"
                                        [disabled]="((current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch) && minFreeAddablesReached == false) || !showBtnNextStp || !subAddablesReached"
                                        type="button" class="mp-btn mp-btn-yellow-on-black weiter mp-btn-rd-upercased">Weiter
                                </button>

                                <button class="mp-btn mp-btn-yellow-on-black mp-btn-rd-upercased"
                                        *ngIf="current_view == 1 && on_edit == true" (click)="onAddToCart(1)"
                                        [disabled]="onAddToCartClicked">Artikel ändern
                                </button>
                                <button class="mp-btn mp-btn-yellow-on-black mp-btn-rd-upercased"
                                        *ngIf="current_view == 1 && on_edit == false" (click)="onAddToCart(0)"
                                        [disabled]="onAddToCartClicked || !menuDownloaded">In den Warenkorb
                                </button>
                            </div>

                        </div>
                        <div class="wahl wahl-top d-none justify-content-center align-items-center mx-auto">
                            <!--Button Zutatenliste anzeigen-->
                            <div class="article-ingredients">
                                <div>
                                    <!--<a routerLink="./" fragment="ingredient-box"
                                       class="btn btn-outline-secondary d-flex justify-content-center align-items-center button">-->
                                    <!-- <a routerLink="./" fragment="ingredient-box-0"-->
                                    <button type="button" (click)="zuButton()"
                                            class="btn btn-outline-secondary d-flex justify-content-center align-items-center mp-btn-rd-upercased w-auto">
                                        <span
                                                *ngIf="current_view == -1 && current_article.price[current_size].has_free_addables">Wähle
                                            Gratis Zutaten</span>
                                        <span
                                                *ngIf="current_view == -1 && !current_article.price[current_size].has_free_addables">Wähle
                                            Gratis Zutaten</span>

                                        <span
                                                *ngIf="current_view == 0 && current_article.price[current_size].has_free_addables">Wähle
                                            Extrazutaten</span>
                                        <span
                                                *ngIf="current_view == 0 && !current_article.price[current_size].has_free_addables">Wähle
                                            Extrazutaten</span>

                                        <span
                                                *ngIf="current_view == 1 && current_article.price[current_size].has_free_addables">Mach´s
                                            als Menü</span>
                                        <span
                                                *ngIf="current_view == 1 && !current_article.price[current_size].has_free_addables">Mach´s
                                            als Menü</span>
                                    </button>
                                    <!--</a>-->
                                </div>
                            </div>
                            <!-- Coupons für Meisterstore -->

                        </div>

                    </div>


                </div>


            </div>

            <div class="bg-black  pt-0" id="ingredient-box">

                <!--   für Test 06.09.22-->
                <!--Zutatenliste-->
                <!-- <ng-container>
                     <div class="ingred-main-article px-0 mb-1">
                         &lt;!&ndash; <div
                                 *ngIf="(on_pickup && !on_happy_hour && !onLunch) || (on_pickup && on_happy_hour && !price.happy_hour_price) || (on_pickup && onLunch && !price.lunch_price)">
                             Grundpreis: {{current_article.name}} {{ price.pickup_price | number: '1.2-5'}}&nbsp;€
                         </div> &ndash;&gt;
                         &lt;!&ndash; <div
                                 *ngIf="(!on_happy_hour && !on_pickup && !onLunch) || (!on_pickup && on_happy_hour && !price.happy_hour_price) || (!on_pickup && onLunch && !price.lunch_price)"> &ndash;&gt;
                         <div class="col-12 col-md-10 col-lg-8 col-xl-10 col-xxl-6 my-md-0 mx-md-auto d-flex flex-nowrap p-0 holder">
                             <div class="grundpreis text-left ingred-list col-8 col-sm-10 d-flex p-1 pr-4 mx-0">
                                 Grundpreis: {{ articleAmmount }} x {{current_article.name}}
                                 {{ price.regular_price | number: '1.2-5'}}&nbsp;€
                             </div>

                             <div class="btn-container col-4 col-sm-2 d-flex px-0 pb-1 align-items-end">
                                 <div class="btn-ingred-left ml-2">
                                     <button type="button" class="mp-btn-minus" (click)="onSubArt()"
                                             [disabled]="articleAmmount<2">
                                         <svg-icon src="assets/icons/icon_minus.svg"
                                                   [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                     </button>
                                 </div>
                                 <div class="btn-ingred-right ml-auto mr-2">
                                     <button type="button" class="mp-btn-plus" (click)="onAddArt()">
                                         <svg-icon src="assets/icons/icon_plus.svg"
                                                   [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                     </button>
                                 </div>
                             </div>
                         </div>


                         &lt;!&ndash; <div *ngIf="on_happy_hour && price.happy_hour_price">
                             Grundpreis: {{current_article.name}} {{price.happy_hour_price | number: '1.2-5'}}&nbsp;€
                         </div> &ndash;&gt;
                         &lt;!&ndash; <ng-container *ngIf="storeData?.master == 1">

                              <div
                                      *ngIf="onLunch && price.lunch_price && !on_pickup && price.lunch_price <= price.regular_price && isLunchCat()">
                                  Grundpreis: {{current_article.name}} {{price.lunch_price | number: '1.2-5'}}&nbsp;€
                              </div>
                              <div
                                      *ngIf="onLunch && price.lunch_price && !on_pickup && price.lunch_price <= price.regular_price && !isLunchCat()">
                                  Grundpreis: {{current_article.name}} {{price.lunch_price | number: '1.2-5'}}&nbsp;€
                              </div>

                              <div
                                      *ngIf="onLunch && price.lunch_price && !on_pickup && price.lunch_price > price.regular_price">
                                  Grundpreis: {{current_article.name}} {{price.regular_price | number: '1.2-5'}}&nbsp;€
                              </div>
                          </ng-container>&ndash;&gt;
                         <ng-container>
                             &lt;!&ndash; <div
                                     *ngIf="onLunch && price.lunch_price && !on_pickup && price.lunch_price <= price.regular_price">
                                 Grundpreis: {{current_article.name}} {{price.lunch_price | number: '1.2-5'}}&nbsp;€
                             </div> &ndash;&gt;
                             &lt;!&ndash; <div
                                     *ngIf="onLunch && price.lunch_price && !on_pickup && price.lunch_price > price.regular_price">
                                 Grundpreis: {{current_article.name}} {{price.regular_price | number: '1.2-5'}}&nbsp;€
                             </div> &ndash;&gt;
                         </ng-container>
                         &lt;!&ndash; <div
                                 *ngIf="onLunch && price.lunch_price && on_pickup && ((price.lunch_price <= price.pickup_price) || current_article.category_id == 4)">
                             Grundpreis: {{current_article.name}} {{price.lunch_price | number: '1.2-5'}}&nbsp;€
                         </div> &ndash;&gt;
                         &lt;!&ndash; <div
                                 *ngIf="onLunch && price.lunch_price && on_pickup && price.lunch_price > price.pickup_price && current_article.category_id !== 4">
                             Grundpreis: {{current_article.name}} {{price.pickup_price | number: '1.2-5'}}&nbsp;€
                         </div> &ndash;&gt;
                     </div>

                     <div class="col-12 col-md-10 col-lg-8 col-xl-10 col-xxl-6 my-md-0 mx-md-auto p-0 holder">

                         <div class="article-detail-ingred-wrapper">
                             <div *ngFor="let ing of added_ing_list, let i = index" class="article-detail-ingred">
                                 <ng-container *ngIf="added_ing_list[i]">
                                     <ng-container *ngIf="added_ing_list[i].free_amount > 0">
                                         <div class="table-ingred  row mx-0 mb-2">
                                             <div class="ingred-list col-8 col-sm-10 d-flex p-1 row mx-0">
                                                 <div class="px-0 col-8 col-sm-10 d-flex">
                                                     <div>
                                                         <span>{{added_ing_list[i].free_amount}}</span>&#160;x&#160;
                                                     </div>
                                                     <div>
                                                         <span> {{added_ing_list[i].ingredients.name}}</span>
                                                     </div>
                                                 </div>
                                                 <div>
                                                     &lt;!&ndash;Gesamtpreis&ndash;&gt;
                                                     <ng-container>
                                                         <p class="my-0 px-0 col-4 col-sm-2">
                                                             Gratis
                                                         </p>
                                                     </ng-container>
                                                 </div>
                                             </div>

                                             <div class="btn-container col-4 col-sm-2 d-flex px-0 pb-1 align-items-end">
                                                 <div class="btn-ingred-left ml-2">
                                                     <button type="button" class="mp-btn-minus" (click)="onSubIng(i)"
                                                             [disabled]="subIngIsDisabled(added_ing_list[i].ing_cat_index, added_ing_list[i].ing_index)">
                                                         <svg-icon src="assets/icons/icon_minus.svg"
                                                                   [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                                     </button>
                                                 </div>
                                                 <div class="btn-ingred-right ml-auto mr-2">
                                                     <button type="button" class="mp-btn-plus" (click)="onAddIng(i)"
                                                             [disabled]="addIngIsDisabled(added_ing_list[i].ing_cat_index, added_ing_list[i].ing_index)">
                                                         <svg-icon src="assets/icons/icon_plus.svg"
                                                                   [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                                     </button>
                                                 </div>
                                             </div>

                                         </div>
                                     </ng-container>

                                     <ng-container *ngIf="added_ing_list[i].amount - added_ing_list[i].free_amount > 0">
                                         <div class="table-ingred row mx-0 mb-2">
                                             <div class="ingred-list col-8 col-sm-10 d-flex p-1 row mx-0">
                                                 <div class="px-0 col-8 col-sm-10 d-flex">
                                                     <div>
                                                         <span>{{added_ing_list[i].amount - added_ing_list[i].free_amount}}</span>&#160;x&#160;
                                                     </div>
                                                     <div>
                                                         <span> {{added_ing_list[i].ingredients.name}}</span>
                                                     </div>
                                                 </div>

                                                 <div>
                                                     &lt;!&ndash;Gesamtpreis&ndash;&gt;
                                                     <ng-container>
                                                         <p class="my-0 px-0 col-4 col-sm-2">
                                                             {{price_ing_sum[i] | number: '1.2-5'}}&nbsp;€
                                                         </p>
                                                     </ng-container>
                                                 </div>
                                             </div>

                                             <div class="btn-container col-4 col-sm-2 d-flex px-0 pb-1 align-items-end">
                                                 <div class="btn-ingred-left ml-2">
                                                     <button type="button" class="mp-btn-minus" (click)="onSubIng(i)"
                                                             [disabled]="subIngIsDisabled(added_ing_list[i].ing_cat_index, added_ing_list[i].ing_index)">
                                                         <svg-icon src="assets/icons/icon_minus.svg"
                                                                   [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                                     </button>
                                                 </div>

                                                 <div class="btn-ingred-right ml-auto mr-2">
                                                     <button type="button" class="mp-btn-plus" (click)="onAddIng(i)"
                                                             [disabled]="addIngIsDisabled(added_ing_list[i].ing_cat_index, added_ing_list[i].ing_index)">
                                                         <svg-icon src="assets/icons/icon_plus.svg"
                                                                   [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                                     </button>
                                                 </div>
                                             </div>

                                         </div>
                                     </ng-container>
                                 </ng-container>
                             </div>
                         </div>

                         <ng-container *ngIf="tempAddedArticles.length > 0">
                             <div class="article-detail-ingred-wrapper mt-3">
                                 <p class="text-center"><strong>Extra Artikel</strong></p>
                                 <div *ngFor="let article of tempAddedArticles, let index = index"
                                      class="article-detail-ingred">
                                     <div class="table-ingred row mx-0 mb-2">
                                         <div class="ingred-list col-8 col-sm-10 d-flex p-1">
                                             <div class="px-0 col-8 col-sm-10 d-flex">
                                                 <div [innerHTML]="article.name">
                                                 </div>
                                                 <div></div>
                                             </div>

                                             <div class="my-0 px-0 col-4 col-sm-2 d-flex align-items-end">
                                                 {{ article.price[0].price | number: '1.2-5' }}&nbsp;€
                                             </div>
                                         </div>

                                         <div class="btn-container col-4 col-sm-2 d-flex px-0 pb-1 align-items-end">
                                             <div class="btn-ingred-left ml-2">
                                                 <button type="button" class="mp-btn-minus"
                                                         (click)="onTempRemoveArticle(index)">
                                                     <svg-icon src="assets/icons/icon_minus.svg"
                                                               [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                                 </button>
                                             </div>
                                             <div class="btn-ingred-right ml-auto mr-2">
                                                 <button type="button" class="mp-btn-plus"
                                                         (click)="onTempAddArticle(article, index)">
                                                     <svg-icon src="assets/icons/icon_plus.svg"
                                                               [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                                 </button>
                                             </div>
                                         </div>

                                     </div>
                                 </div>
                             </div>
                         </ng-container>

                     </div>
                 </ng-container>-->
                <!--   /für Test 6.09.22-->

                <!-- INCLUDE FREE ADDABLES VIEW WHEN PRODUCT GROUP HAS FREE ADDABLES -->
                <ng-container
                        *ngIf="current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch">
                    <!-- ######{{ current_article.price[current_size].has_free_addables }}###### -->
                    <app-add-free-ingredients
                            *ngIf="current_view == -1 && current_article.price[current_size].has_free_addables"
                            [sub_cat_id]="sub_cat_id" [ingredient_list]="ingredient_list"
                            [current_size_index]="current_size" [added_ing_list]="added_ing_list"
                            [price_ing_sum]="price_ing_sum" [amount]="amount" [onLunch]="onLunch"
                            [potatoAdded]="potatoAdded" [show_tab_of_free_addables]="show_tab_of_free_addables"
                            [ingredientListLoaded]="ingredientListLoaded" (changed_ing_emitter)="recalculatePrice()"
                            (sub_ing_emitter)="onSubIng($event)" (add_ing_emitter)="onAddIng($event)">
                    </app-add-free-ingredients>
                </ng-container>


                <app-add-ingredients *ngIf="current_view == 0 && hasFreeIng" [sub_cat_id]="sub_cat_id"
                                     [ingredient_list]="ingredient_list" [current_size_index]="current_size"
                                     [added_ing_list]="added_ing_list" [price_ing_sum]="price_ing_sum" [amount]="amount"
                                     [onLunch]="onLunch" [potatoAdded]="potatoAdded" [showFreeAddNow]="showFreeAddNow"
                                     [notFreeTabQ]="notFreeTabQ"
                                     [ingredientListLoaded]="ingredientListLoaded"
                                     (changed_ing_emitter)="recalculatePrice()"
                                     (sub_ing_emitter)="onSubIng($event)" (add_ing_emitter)="onAddIng($event)">
                </app-add-ingredients>
                <app-add-article *ngIf="current_view == 1" 
                                [addedArticles]="tempAddedArticles"
                                [artForMenu]="menu_article_list" 
                                (tempAddedArticlesEmitter)="onTempArticleAdded($event)"
                                (tempRemovedArticlesEmitter)="onTempArticleRemoved($event)">
                </app-add-article>
            </div>

            <div class="bg-black clearfix py-0 ">
                <div class="article-price-wrap mb-2 text-center">
                    <span
                            *ngIf="((current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch) && minFreeAddablesReached == false) || !showBtnNextStp || !subAddablesReached"
                            class="text-danger">Du hast noch nicht alle Gratiszutaten gewählt </span>
                </div>


                <!-- DREI BUTTONS -->
                <div class="article-price-wrap d-flex flex-wrap justify-content-around align-items-center col-md-10 mt-md-3 mb-md-2 mb-xxl-3 col-lg-8 col-xl-10 col-xxl-8 col-xxxl-6">


                    <div class="zurueck  zurueck-top d-flex justify-content-center align-items-center">
                        <div class="article-btn-left">
                            <button
                                    *ngIf="current_view == -1 || 
                                    (current_view == 0 && !articleHasFreeIngridients) || 
                                    (current_view == 1 && !articleHasIngridients)"
                                    (click)="router.navigate(['shop'])" type="button" class="mp-btn-prev p-0">
                                <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                            </button>
                            <!--Conditions 1-->
                            <button
                                    *ngIf="current_view == 0 && current_article.price[current_size].has_free_addables && (current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch)"
                                    (click)="onStepContinue(-1)" type="button" class="mp-btn-prev p-0">
                                <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                            </button>

                            <!--Conditions 2-->
                            <button *ngIf="current_view == 1 && hasFreeIng" (click)="onStepContinue(0)"
                                    type="button" class="mp-btn-prev p-0">
                                <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                            </button>

                            <!--Conditions 3-->
                            <button
                                    *ngIf="current_view == 1 && !hasFreeIng && current_article.price[current_size].has_free_addables && (current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch)"
                                    (click)="onStepContinue(-1)" type="button" class="mp-btn-prev p-0">
                                <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                            </button>

                        </div>
                    </div>
                    <div class="contaner-wahl-weiter py-1 d-flex flex-wrap justify-content-around align-items-center">
                                        <div class=" contaner-wahl-weiter-bottom mx-auto">
                                            <div class="added-to-cart added-to-cart-hide">
                                                <div class="bg-shop-closed">
                                                    <div class="dialog-added">
                                                        <p class="added_header font-weight-normal text-center">
                                                            Deine Artikel wurden in den Warenkorb gelegt.
                                                        </p>
                                                        <div class="col p-0">
                                                            <p class="added_text text-center">Möchtest du zur Kasse oder weiter einkaufen?</p>
                                                            <p class="d-flex justify-content-between font-weight-bolder">
                                                                <button class="text-uppercase mp-btn-narrow-sm_pad mp-btn button-black"
                                                                    routerLink="/shop">Weiter
                                                                    einkaufen</button>
                                                                <button class="text-uppercase mp-btn-narrow-sm_pad mp-btn mp-btn-yellow-on-white"
                                                                    routerLink="/shop/bestellen/warenkorb">Zur Kasse</button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--Button weiter/WK-->
                                            <div class="article-btn-right d-flex flex-wrap justify-content-center align-items-center">
                                        
                                                <button *ngIf="current_view == -1 && hasFreeIng" (click)="onStepContinue(0)"
                                                    [disabled]="(current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch) && minFreeAddablesReached == false"
                                                    type="button" class="mp-btn mp-btn-yellow-on-black weiter  mp-btn-rd-upercased">Weiter
                                                </button>
                                        
                                                <button *ngIf="current_view == -1 && !hasFreeIng" (click)="onStepContinue(1)"
                                                    [disabled]="(current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch) && minFreeAddablesReached == false"
                                                    type="button" class="mp-btn mp-btn-yellow-on-black weiter mp-btn-rd-upercased">Weiter
                                                </button>
                                        
                                                <!-- ***v*** -->
                                                <button *ngIf="current_view == 0" (click)="onStepContinue(1)"
                                                    [disabled]="((current_article.price[current_size].always_show_free_addables || !current_article.price[current_size].always_show_free_addables && onLunch) && minFreeAddablesReached == false) || !showBtnNextStp || !subAddablesReached"
                                                    type="button" class="mp-btn mp-btn-yellow-on-black weiter mp-btn-rd-upercased">Weiter
                                                </button>
                                        
                                                <button class="mp-btn mp-btn-yellow-on-black mp-btn-rd-upercased" *ngIf="current_view == 1 && on_edit == true"
                                                    (click)="onAddToCart(1)" [disabled]="onAddToCartClicked">Artikel ändern
                                                </button>
                                                <button class="mp-btn mp-btn-yellow-on-black mp-btn-rd-upercased" *ngIf="current_view == 1 && on_edit == false"
                                                    (click)="onAddToCart(0)" [disabled]="onAddToCartClicked || !menuDownloaded">In den Warenkorb
                                                </button>
                                            </div>
                                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>


<!-- begin: popup -->
<ng-container *ngIf="showInfoBox">
    <div class="bg-mp-dialog">
        <div class="mp-dialog">
            <div class="title-mp-dialog">
                Allergene & Zusatzstoffe
            </div>
            <div class="cont-mp-dialog">
                <h3 class="mp-font-large-yellow">{{ infoBoxProduct.name }}</h3>
                <span [innerHTML]="infoBoxProduct.description"></span>
                <ng-container *ngIf="infoBoxProduct.description_addition">
                    <span class="description-addition" [innerHTML]="infoBoxProduct.description_addition"></span>
                </ng-container>
                <br><br>
                <h4>Zusatzstoffe:</h4>
                <ul>
                    <ng-container *ngFor="let marking of infoBoxProduct.markings">
                        <li *ngIf="marking.type === 'Zusatzstoff'">{{ marking.abbreviation}} - {{ marking.name }}</li>
                    </ng-container>
                </ul>
                <br>
                <h4>Allergene:</h4>
                <ul>
                    <ng-container *ngFor="let marking of infoBoxProduct.markings">
                        <li *ngIf="marking.type === 'Allergen'">{{ marking.abbreviation}} - {{ marking.name }}</li>
                    </ng-container>
                </ul>
                <br>
                <h4>BITTE BEACHTE:</h4>
                <p>Die Zusatzstoffe und Allergene können sich beim Hinzufügen oder Wechseln von Zutaten ändern.</p>
                <br>
                <div class="btn-mp-dialog text-center">
                    <button class="mp-btn mp-btn-yellow-on-white" (click)="showInfoBox = false">Ok</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- end: popup -->
