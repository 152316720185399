var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MainService } from "./main.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Response } from "@angular/http";
import { catchError, retry, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { of } from "rxjs/internal/observable/of";
import { ReplaySubject, interval } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./main.service";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    }),
};
var FrontendService = /** @class */ (function () {
    // private stuff - - - - - - - - - - - - - - - -
    // constructor   - - - - - - - - - - - - - - - -
    function FrontendService(main_service, http, router, document) {
        var _this = this;
        this.main_service = main_service;
        this.http = http;
        this.router = router;
        this.document = document;
        // public stuff  - - - - - - - - - - - - - - - -
        // create observable for order success
        // orderSuccessful: boolean;
        this.orderResponseErrorSubject = new ReplaySubject();
        this.amazonPayGetOrderResponseSubject = new ReplaySubject();
        this.amazonPayConfirmOrderResponseSubject = new ReplaySubject();
        // the WINORDER TRACKING STATUS from the db entry; not "status"
        this.checkWinorderTrackingStatusSubject = new ReplaySubject();
        this.getMollieUrlSubject = new ReplaySubject();
        this.mollieresp = '';
        this.getOpeningHoursNowDeliv = null;
        this.getOpeningHoursNowPickup = null;
        this.cache = {};
        this.cacheTTL = 60000; // Час життя кешу (1 хвилина)
        this.isTabActive = true;
        document.addEventListener('visibilitychange', function () {
            _this.isTabActive = !document.hidden;
        });
    }
    FrontendService.prototype.isCacheValid = function (key) {
        return (this.cache[key] &&
            Date.now() - this.cache[key].timestamp < this.cacheTTL);
    };
    FrontendService.prototype.getCachedResponse = function (key) {
        return this.cache[key] ? this.cache[key].data : null;
    };
    FrontendService.prototype.cacheResponse = function (key, data) {
        this.cache[key] = { timestamp: Date.now(), data: data };
    };
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions
    FrontendService.prototype.getOrderSuccessful = function () {
        return this.orderResponseErrorSubject.asObservable();
    };
    FrontendService.prototype.getAmazonPayOrderResponse = function () {
        return this.amazonPayGetOrderResponseSubject.asObservable();
    };
    FrontendService.prototype.getAmazonPayConfirmOrderResponse = function () {
        return this.amazonPayConfirmOrderResponseSubject.asObservable();
    };
    FrontendService.prototype.getWinorderTrackingStatus = function () {
        return this.checkWinorderTrackingStatusSubject.asObservable();
    };
    FrontendService.prototype.getMollieUrl = function () {
        return this.getMollieUrlSubject.asObservable();
    };
    // holt die Daten zu allen Hauptkategorien aus der DB + übergiebt sie - zB für Anzeige in der Navigation
    FrontendService.prototype.getCategories = function () {
        var _this = this;
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_categories';
        var url = this.main_service.getFrontendUrl() + '/productgroups';
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log('fetched categories from ' + url); }), catchError(this.handleError("getHero id=" + name)));
    };
    FrontendService.prototype.getMasterCategories = function () {
        var _this = this;
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_categories';
        var url = this.main_service.getFrontendUrl() + '/productgroupmaster';
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log('fetched categories from ' + url); }), catchError(this.handleError("getHero id=" + name)));
    };
    // erstellt Liste alelr Artikel einer Kategorie und gibt sie refactored zurück (Listenansicht)
    FrontendService.prototype.getArticleListByCategory = function (sub_cat_id, delivType, delivDate) {
        var _this = this;
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_article_list&category=' + sub_cat_id;
        var url = this.main_service.getFrontendUrl() + '/productgroupmaster/' + sub_cat_id + '/products/' + delivType + '/' + delivDate;
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log("fetched article list from: " + url); }), catchError(this.handleError('getHeroes', [])));
    };
    // übergibt alle Infos eines Artikels zu einer bestimmten ID (Detailansicht)
    FrontendService.prototype.getArticleByID = function (sub_cat_id) {
        var _this = this;
        var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        var loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
        loadedDeliveryDate.setHours(parseInt(loadedDeliveryTime.toString().substring(0, 2)));
        loadedDeliveryDate.setMinutes(parseInt(loadedDeliveryTime.toString().substring(3, 5)));
        var delivDate = loadedDeliveryDate.toString();
        var delivType = (sessionStorage.getItem('pickup') === '1') ? 'pickup' : 'delivery';
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_article_detail&id=' + sub_cat_id;
        var url = this.main_service.getFrontendUrl() + '/products/' + sub_cat_id + '/' + delivType + '/' + delivDate;
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log("fetched article detail from: " + url); }), catchError(this.handleError('getHeroes', [])));
    };
    // same wie get_article_detail nur eben für Aktionsartikel
    FrontendService.prototype.getSpArticleByID = function (sub_cat_id) {
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_special_offer_article_detail&id=' + sub_cat_id;
        var _this = this;
        var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        var loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
        loadedDeliveryDate.setHours(parseInt(loadedDeliveryTime.toString().substring(0, 2)));
        loadedDeliveryDate.setMinutes(parseInt(loadedDeliveryTime.toString().substring(3, 5)));
        var delivDate = loadedDeliveryDate.toString();
        var delivType = (sessionStorage.getItem('pickup') === '1') ? 'pickup' : 'delivery';
        var url = this.main_service.getFrontendUrl() + '/products/' + sub_cat_id + '/' + delivType + '/' + delivDate;
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log("fetched article detail from: " + url); }), catchError(this.handleError('getHeroes', [])));
    };
    // gibt alle Zutaten geordnet nach Kategorie zurück, mit der Artikel einer SubCat erweitert werden kann
    // is build on $request->segment(x) on server side, do not change the order of url segments!!!
    FrontendService.prototype.getIngListBySubCat = function (sub_cat_id) {
        var _this = this;
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_ingredient_list&id=' + sub_cat_id;
        var url = this.main_service.getFrontendUrl() + '/productgroups/' + sub_cat_id + '/ingredients';
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log("fetched ing list for a subcat from: " + url); }), catchError(this.handleError('getHeroes', [])));
    };
    FrontendService.prototype.getIngListBySubCatReload = function (sub_cat_id, ingridientsList, amount, operationType, current_size) {
        var _this = this;
        if (operationType === void 0) { operationType = false; }
        if (current_size === void 0) { current_size = false; }
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_ingredient_list&id=' + sub_cat_id;
        var url = this.main_service.getFrontendUrl() + '/productgroups/' + sub_cat_id + '/ingredients';
        var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        var loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
        loadedDeliveryDate.setHours(parseInt(loadedDeliveryTime.toString().substring(0, 2)));
        loadedDeliveryDate.setMinutes(parseInt(loadedDeliveryTime.toString().substring(3, 5)));
        var delivDate = loadedDeliveryDate.toString();
        var delivType = (sessionStorage.getItem('pickup') === '1') ? 'pickup' : 'delivery';
        var arr = {
            'ingridientsArr': ingridientsList,
            'productAmount': amount,
            'deliveryDate': delivDate,
            'deliveryType': delivType,
            'operationType': operationType,
            'current_size': current_size
        };
        return this.http.post(url, JSON.stringify(arr), httpOptions)
            .pipe(retry(3), tap(function (data) { return _this.log("fetched ing list for a subcat from: " + url); }), catchError(this.handleError('getHeroes', [])));
    };
    /* Get all ingredients by product group subcategory - new get method with async-await syntax */
    FrontendService.prototype.getIngredientsByProductSubCategory = function (productSubCategoryId) {
        var url = this.main_service.getFrontendUrl() + '/productgroups/' + productSubCategoryId + '/ingredients';
        return this.http.get(url).toPromise();
    };
    /* Get the product from api by given product id */
    FrontendService.prototype.getProductByID = function (productId) {
        var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        var loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
        loadedDeliveryDate.setHours(parseInt(loadedDeliveryTime.toString().substring(0, 2)));
        loadedDeliveryDate.setMinutes(parseInt(loadedDeliveryTime.toString().substring(3, 5)));
        var delivDate = loadedDeliveryDate.toString();
        var delivType = (sessionStorage.getItem('pickup') === '1') ? 'pickup' : 'delivery';
        var url = this.main_service.getFrontendUrl() + '/products/' + productId + '/' + delivType + '/' + delivDate;
        return this.http.get(url).toPromise();
    };
    /* Get the ingredient from api by given ingredient id */
    FrontendService.prototype.getIngredientByID = function (ingredientId) {
        var url = this.main_service.getFrontendUrl() + '/ingredients/' + ingredientId;
        return this.http.get(url).toPromise();
    };
    // async test2(productSubCategoryId: number): Promise<any> {
    //     const url = this.main_service.getFrontendUrl() + '/productgroups/' + productSubCategoryId + '/ingredients';
    //     const response = await this.http.get<any>(url).toPromise();
    //     return response.json();
    // }
    FrontendService.prototype.getAllBooks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = this.main_service.getFrontendUrl() + '/productgroups/' + 4 + '/ingredients';
                        return [4 /*yield*/, this.http.get(url).toPromise()];
                    case 1: 
                    // wait for 3 seconds (just for the sake of this example)
                    //   await delay();
                    // GET information about each book
                    return [2 /*return*/, _a.sent()];
                    case 2:
                        error_1 = _a.sent();
                        /* If any of the awaited promises was rejected, this catch block would catch the rejection reason */
                        return [2 /*return*/, error_1];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // gibt alle Artikel geordnet nach Kategorie zurück, die als Menüoption angeboten werden sollen
    FrontendService.prototype.getArticleForMenu = function () {
        var _this = this;
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_menue_article';
        var delivType = this.getPickup();
        var delivDate = this.getSelectedDateTime();
        var url = this.main_service.getFrontendUrl() + '/products-for-menu' + '/' + delivType + '/' + delivDate;
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log("fetched all menu articles from: " + url); }), catchError(this.handleError('getHeroes', [])));
    };
    // gibt zu einer Adresse (PLZ + Ortsteil) den zugeordneten Mindestbestllwert zurück
    FrontendService.prototype.getMinVal = function (plz, district) {
        var _this = this;
        // const url = this.main_service.getFrontendUrl() + '?plz=' + plz + '&district=' + district;
        var url = this.main_service.getFrontendUrl() + '/get-min-order-value?plz=' + plz + '&district=' + district;
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log("fetched min val for plz and district from: " + url); }), catchError(this.handleError('getHeroes', [])));
    };
    // validates couponcode
    FrontendService.prototype.checkCouponCode = function (code) {
        var _this = this;
        // const url = this.main_service.getFrontendUrl() + '?plz=' + plz + '&district=' + district;
        var url = this.main_service.getFrontendUrl() + '/check-coupon-code/' + code;
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log("check coupon code from: " + url); }), catchError(this.handleError('getHeroes', [])));
    };
    // get all deilvery areas from backend (for single shop only)
    FrontendService.prototype.getDeliveryAreas = function () {
        var _this = this;
        // const url = this.main_service.getFrontendUrl() + '?plz=' + plz + '&district=' + district;
        var url = this.main_service.getFrontendUrl() + '/get-delivery-areas';
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log("fetched from: " + url); }), catchError(this.handleError('getHeroes', [])));
    };
    // Startseite/Aktion-Funktionen  - - - - - - - - - - - - - - - - - - - - - -
    // same wie get_article_list nur für alle aktuell relevanten Aktionsartikel
    FrontendService.prototype.getSpOfferArticle = function () {
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_specal_offer_article';
        var _this = this;
        var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        var loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
        loadedDeliveryDate.setHours(parseInt(loadedDeliveryTime.toString().substring(0, 2)));
        loadedDeliveryDate.setMinutes(parseInt(loadedDeliveryTime.toString().substring(3, 5)));
        var delivDate = loadedDeliveryDate.toString();
        var delivType = (sessionStorage.getItem('pickup') === '1') ? 'pickup' : 'delivery';
        var url = this.main_service.getFrontendUrl() + '/special-offers' + '/' + delivType + '/' + delivDate;
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log("fetched all special offer articles from: " + url); }), catchError(this.handleError('getHeroes', [])));
    };
    // holt infos (Bild+Name) für den Header der Aktionsseite
    // getSpOfferHeader(){
    //     const url = this.main_service.getFrontendUrl() + '?data=' + 'get_specal_offer_header';
    //     return this.http.get(url)
    //         .pipe(
    //             retry(3),
    //             tap(data => this.log(`fetched all special offer articles from: ` + url)),
    //             catchError(this.handleError('getHeroes', []))
    //         );
    // }
    // gibt alle infos zu den Slidern auf der Startseite wieder
    FrontendService.prototype.getSliderInfo = function () {
        var _this = this;
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_slider_info';
        var url = this.main_service.getFrontendUrl() + '/get-slider-info';
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log("fetched slider infos from: " + url); }), catchError(this.handleError('getHeroes', [])));
    };
    // Öffnungszeiten-Funktionen  - - - - - - - - - - - - - - - - - - - - - -
    // enthält öffnungszeiten + happy-hour bereiche für ein bestimmtes Datum (date im Format JJJ-MM-(T)T angeben)
    FrontendService.prototype.onGetOpeningHoursByDate = function (date, typeDeliv) {
        var _this = this;
        if (typeDeliv === void 0) { typeDeliv = false; }
        var deliveryType = typeDeliv || this.getPickup();
        var key = "onGetOpeningHoursByDate:" + date + ":" + deliveryType;
        if (!this.isTabActive) {
            console.warn('Вкладка неактивна. Запит onGetOpeningHoursByDate скасовано');
            return Promise.resolve(this.getCachedResponse(key));
        }
        if (this.isCacheValid(key)) {
            console.log('Повертається кешований результат: onGetOpeningHoursByDate');
            return Promise.resolve(this.getCachedResponse(key));
        }
        var url = this.main_service.getFrontendUrl() +
            ("/get-opening-hours-by-date/" + date + "?deliveryType=" + deliveryType);
        return this.http
            .get(url)
            .toPromise()
            .then(function (data) {
            console.log("Fetched opening hours for date: " + date);
            _this.cacheResponse(key, data);
            return data;
        })
            .catch(this.handleError('onGetOpeningHoursByDate', []));
    };
    // returns the opening hours for today as json (plus happy hour, lunch etc)
    FrontendService.prototype.getOpeningHoursNow = function (queryDate) {
        var _this = this;
        if (queryDate === void 0) { queryDate = null; }
        var deliveryType = this.getPickup();
        var key = "getOpeningHoursNow:" + queryDate + ":" + deliveryType + "}";
        if (!this.isTabActive) {
            console.warn('Вкладка неактивна. Запит getOpeningHoursNow скасовано');
            return of(this.getCachedResponse(key));
        }
        if (this.isCacheValid(key)) {
            return of(this.getCachedResponse(key));
        }
        var url = this.main_service.getFrontendUrl() +
            '/get-opening-hours-today?deliveryType=' +
            deliveryType;
        return this.http.get(url).pipe(retry(3), tap(function (data) {
            console.log("Fetched opening hours from: " + url);
            if (typeof data[0] !== 'undefined') {
                data[0].queryDate = queryDate;
            }
            _this.cacheResponse(key, data);
        }), catchError(this.handleError('getOpeningHoursNow', [])));
    };
    // returns array of opening hours in format used for frontend output in footer and wrapper
    FrontendService.prototype.getOpeningHours = function (opening_type, delivType) {
        var _this = this;
        if (delivType === void 0) { delivType = false; }
        var deliveryType = delivType || this.getPickup();
        var key = "getOpeningHours:" + opening_type + ":" + deliveryType;
        if (!this.isTabActive) {
            console.warn('Вкладка неактивна. Запит getOpeningHours скасовано');
            return of(this.getCachedResponse(key));
        }
        if (this.isCacheValid(key)) {
            console.log('Повертається кешований результат: getOpeningHours');
            return of(this.getCachedResponse(key));
        }
        var url = this.main_service.getFrontendUrl() +
            ("/get-opening-hours/" + opening_type + "?deliveryType=" + deliveryType);
        return this.http.get(url).pipe(retry(3), tap(function (data) {
            console.log("Fetched opening hours from: " + url);
            _this.cacheResponse(key, data);
        }), catchError(this.handleError('getOpeningHours', [])));
    };
    FrontendService.prototype.getOpeningHoursArr = function (opening_type, selectedDate, dateObj) {
        var _this = this;
        if (dateObj === void 0) { dateObj = null; }
        var key = "getOpeningHoursArr:" + opening_type + ":" + selectedDate;
        if (!this.isTabActive) {
            console.warn('Вкладка неактивна. Запит getOpeningHoursArr скасовано');
            return Promise.resolve(this.getCachedResponse(key));
        }
        if (this.isCacheValid(key)) {
            console.log('Повертається кешований результат: getOpeningHoursArr');
            return Promise.resolve(this.getCachedResponse(key));
        }
        var url = this.main_service.getFrontendUrl() +
            ("/open-times?date=" + selectedDate + "&delivery=" + opening_type);
        return this.http
            .get(url)
            .toPromise()
            .then(function (data) {
            console.log("Fetched opening hours array for: " + selectedDate);
            data['queryDate'] = dateObj;
            _this.cacheResponse(key, data);
            return data;
        })
            .catch(this.handleError('getOpeningHoursArr', []));
    };
    // Nutzer-Funktionen  - - - - - - - - - - - - - - - - - - - - - -
    // Updatet Nutzerdaten in der DB, anhand eines übergebenen JSON
    FrontendService.prototype.onEditUser = function (user_data) {
        var url = this.main_service.getFrontendUrl() + '?data=' + 'edit_user_data';
        this.http.post(url, user_data)
            .subscribe(function (res) {
            // console.log(res);
        }, function (err) {
            console.log("Error occured");
            console.log(err);
        });
    };
    // verändert die Mailadresse eines Users in der DB (nicht Firebase)
    FrontendService.prototype.changeMail = function (uid, new_mail, token) {
        var url = this.main_service.getFrontendUrl() + '?data=' + 'change_mail';
        var user_data = {
            'uid': uid,
            'new_mail': new_mail,
            'token': token,
        };
        this.http.post(url, JSON.stringify(user_data))
            .subscribe(function (res) {
            // console.log(res);
        }, function (err) {
            console.log("Error occured");
            console.log(err);
        });
    };
    // löscht User aus der DB (nicht firebase)
    FrontendService.prototype.deleteUser = function (token) {
        var _this = this;
        var url = this.main_service.getFrontendUrl() + '?data=' + 'delete_user&token=' + token;
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log("fetched slider infos from: " + url); }), catchError(this.handleError('getHeroes', [])));
    };
    //erstellt Nutzer in der DB (nicht Firebase)
    FrontendService.prototype.onCreateUser = function (data) {
        var url = this.main_service.getFrontendUrl() + '?data=' + 'create_user';
        this.http.post(url, data)
            .subscribe(function (res) {
            // console.log(res);
        }, function (err) {
            console.log("Error occured");
            console.log(err);
        });
    };
    // erhält Adressdaten eines Nutzers anhand des Tokens
    FrontendService.prototype.fetchAdressData = function (token) {
        var _this = this;
        var url = this.main_service.getFrontendUrl() + '?data=' + 'get_adress&token=' + token;
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log('fetched Adress Data for ' + ':' + url); }), catchError(this.handleError('getHeroes', [])));
    };
    // NO USAGE
    // gibt alle angelegten Zutatenkategorien zurück
    FrontendService.prototype.getIngCats = function () {
        var _this = this;
        var url = this.main_service.getFrontendUrl() + '?data=' + 'get_ing_categories';
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log("fetched ing categories from: " + url); }), catchError(this.handleError('getHeroes', [])));
    };
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // sendet die Bestellung ans BE zur validierung + verarbeitung
    FrontendService.prototype.onSendData = function (data, shopIsOpen) {
        var _this = this;
        // const url = this.main_service.getSubmitUrl() + '?data=' + 'send_offer';
        var url = this.main_service.getFrontendUrl() + '/orders';
        // log the post url
        // send the request
        this.http.post(url, JSON.stringify(data), httpOptions)
            .subscribe(function (res) {
            if (res.data.mollieUrl) {
                window.open(res.data.mollieUrl, '_blank', 'location=yes,scrollbars=yes,status=yes');
                sessionStorage.setItem('paymentUrl', res.data.mollieUrl);
                _this.getMollieUrlSubject.next(res.data.mollieUrl);
            }
            _this.orderResponseErrorSubject.next(res);
            if (shopIsOpen) {
                // request periodically
                _this.checkWinorderTrackingStatus = interval(5000).subscribe(function () {
                    _this.onCheckWinorderTrackingStatus(res.data.orderID);
                });
            }
            else {
                _this.onCheckWinorderTrackingStatus(res.data.orderID);
            }
        }, function (err) {
            console.log('Error occured');
            console.log(err);
            _this.orderResponseErrorSubject.next(err);
        });
    };
    FrontendService.prototype.onCheckWinorderTrackingStatus = function (id) {
        var _this = this;
        var url = this.main_service.getFrontendUrl() + '/check-order-status/' + id;
        this.http.get(url)
            .pipe(retry(1), tap(function (data) { return _this.log('fetched Adress Data for ' + ':' + url); }), catchError(this.handleError('getHeroes', []))).subscribe(function (res) {
            // check winorder tracking status; 2 = order is beeing prepared
            if (res.orderStatus === 2) {
                _this.checkWinorderTrackingStatusSubject.next(res.orderStatus);
                _this.checkWinorderTrackingStatus.unsubscribe();
            }
            else {
                _this.checkWinorderTrackingStatusSubject.next(res.orderStatus);
            }
            if (res.url && res.url != sessionStorage.getItem('paymentUrl')) {
                sessionStorage.setItem('paymentUrl', res.url);
                _this.getMollieUrlSubject.next(res.url);
            }
        }, function (err) {
            console.log('Error occured: ' + err);
        });
    };
    FrontendService.prototype.resetWinorderTrackingStatus = function () {
        this.checkWinorderTrackingStatusSubject.next(-1);
        if (this.checkWinorderTrackingStatus) {
            this.checkWinorderTrackingStatus.unsubscribe();
        }
    };
    FrontendService.prototype.getSettingsFromBackend = function () {
        var _this = this;
        var url = this.main_service.getFrontendUrl() + '/get-settings';
        return this.http.get(url)
            .pipe(retry(3), tap(function (data) { return _this.log("fetched data from getSettingsFromBackend(): " + url); }), catchError(this.handleError('getSettingsFromBackend', [])));
    };
    FrontendService.prototype.getStoreData = function () {
        var _this = this;
        if (!this.isTabActive) {
            console.warn('Вкладка неактивна. Запит getStoreData скасовано');
            return of([]); // Повертаємо порожній результат
        }
        if (this.storeData) {
            console.log('Повертається кешований результат: getStoreData');
            return of(this.storeData); // Повертаємо кешований результат
        }
        var url = this.main_service.getFrontendUrl() + '/get-store-data';
        return this.http.get(url).pipe(retry(3), tap(function (data) {
            console.log('Fetched store data from backend');
            _this.document.body.setAttribute('data-theme', data['theme']);
            _this.setStoreData(data); // Зберігаємо дані в локальний кеш
        }), catchError(this.handleError('getStoreData', [])));
    };
    FrontendService.prototype.setStoreData = function (data) {
        this.storeData = data;
        localStorage.setItem('storedata', JSON.stringify(this.storeData));
    };
    // sendet die Bestellung ans BE zur validierung + verarbeitung
    FrontendService.prototype.onAmazonPaySetOrderDetails = function (data) {
        var _this = this;
        var url = this.main_service.getFrontendUrl() + '/amazonPaySetOrderDetails';
        // send the request
        this.http.post(url, JSON.stringify(data), httpOptions)
            .subscribe(function (res) {
            _this.amazonPayGetOrderResponseSubject.next(res);
        }, function (err) {
            console.log('ERROR FROM BACKEND AMAZON PAY SET ORDER DETAILS' + err);
        });
    };
    FrontendService.prototype.onAmazonPayConfirmOrder = function (data) {
        var _this = this;
        var url = this.main_service.getFrontendUrl() + '/amazonPayConfirmOrder';
        // send the request
        this.http.post(url, JSON.stringify(data), httpOptions)
            .subscribe(function (res) {
            _this.amazonPayConfirmOrderResponseSubject.next(res);
        }, function (err) {
            console.log('ERROR FROM BACKEND AMAZON PAY SET ORDER DETAILS' + err);
        });
    };
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions
    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    FrontendService.prototype.handleError = function (operation, result) {
        var _this = this;
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            if (error.status === 0) { // Server nicht erreichbar
                _this.router.navigate(['./server-offline']);
            }
            else {
                _this.router.navigate(['./']);
            }
            // TODO: better job of transforming error for user consumption
            // this.log(`${operation} failed: ${error.message}`);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    FrontendService.prototype.log = function (message) {
        // console.log('Action: ' + message);
    };
    FrontendService.prototype.checkMolliePayment = function (hash) {
        var _this = this;
        var url = this.main_service.getFrontendUrl() + '/mollie-payment-check?hash=' + hash;
        /*return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched ing categories from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );*/
        return this.http.get(url)
            .toPromise()
            .then(function (data) {
            var response = data;
            _this.mollieresp = data;
        });
    };
    FrontendService.prototype.getCartItems = function (itemsArr) {
        var _this = this;
        var key = "getCartItems:" + JSON.stringify(itemsArr);
        if (!this.isTabActive) {
            return Promise.resolve(this.getCachedResponse(key));
        }
        if (this.isCacheValid(key)) {
            console.log('Повертається кешований результат: getCartItems');
            return Promise.resolve(this.getCachedResponse(key));
        }
        return new Promise(function (resolve, reject) {
            var url = _this.main_service.getFrontendUrl() + '/checkout';
            _this.http.post(url, JSON.stringify(itemsArr), httpOptions).subscribe(function (res) {
                console.log('Fetched cart items from backend');
                _this.cacheResponse(key, res); // Зберігаємо відповідь у кеш
                resolve(res);
            }, function (err) {
                console.log('ERROR FROM BACKEND: ' + err);
                reject(err);
            });
        });
    };
    FrontendService.prototype.getPickup = function () {
        var typeDeliv = 'notSet';
        if (sessionStorage.getItem('pickup')) {
            if (sessionStorage.getItem('pickup') == '1') {
                typeDeliv = 'pickup';
            }
            else if (sessionStorage.getItem('pickup') == '0') {
                typeDeliv = 'delivery';
            }
        }
        return typeDeliv;
    };
    FrontendService.prototype.getSelectedDateTime = function () {
        var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        var loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
        loadedDeliveryDate.setHours(parseInt(loadedDeliveryTime.toString().substring(0, 2)));
        loadedDeliveryDate.setMinutes(parseInt(loadedDeliveryTime.toString().substring(3, 5)));
        var delivDate = loadedDeliveryDate.toString();
        return delivDate;
    };
    FrontendService.ngInjectableDef = i0.defineInjectable({ factory: function FrontendService_Factory() { return new FrontendService(i0.inject(i1.MainService), i0.inject(i2.HttpClient), i0.inject(i3.Router), i0.inject(i4.DOCUMENT)); }, token: FrontendService, providedIn: "root" });
    return FrontendService;
}());
export { FrontendService };
