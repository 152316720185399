<!--<button class="log-button" (click)="onLog()">log</button>-->

<ng-container *ngIf="!ready">
    <!-- <div class="text-center color-primary centered-wrapper">
        <div class="all-centered">
            <div class="content-centered">
                <svg-icon src="assets/icons/icon-loading.svg" [svgStyle]="{ 'fill':'currentColor' }" alt="Preloader"></svg-icon>
            </div>
        </div>
    </div> -->
</ng-container>

<ng-container *ngIf="ready">

    <div class="added-ingredients-wrapper">
        <!-- <span *ngIf="amount[i_cat][i_ing]>0"> {{amount[i_cat][i_ing]}} x {{ing.name}}</span> -->
    </div>


        <h2 class="add-ingredient-headline">Wähle Extrazutaten</h2>

        <ng-container *ngIf="!ingredientListLoaded">
            <!-- <div id="vual" class="d-flex justify-content-center align-items-center" [ngStyle]={height:not_loaded_div_height}>
                <img src="assets/img/preloader_onwhite.gif" alt="Preloader">
            </div> -->
            <!-- <div class="text-center color-primary centered-wrapper">
                <div class="all-centered">
                    <div class="content-centered">
                        <svg-icon src="assets/icons/icon-loading.svg" [svgStyle]="{ 'fill':'currentColor' }" alt="Preloader"></svg-icon>
                    </div>
                </div>
            </div> -->
        </ng-container>

        <ng-container *ngIf="ingredientListLoaded || !ingredientListLoaded">
            <ng-container *ngIf="!(ingredient_list.length > 0)">
                <p class="text-center mt-4 mb-4"><strong>Für dieses Produkt sind keine Extrazutaten verfügbar.</strong>
                </p>
            </ng-container>

            <!--Tabs erstellen und anzeigen-->
            <ng-container *ngIf="ingredient_list.length > 0">
                <div id="btn-table" class="btn-group" role="group" aria-label="Basic example" style="width: 100%;">
                    <ng-container *ngFor="let ing_cat of ingredient_list, let i_cat = index">
                        <ng-container
                            *ngIf="!ing_cat.sizes_free_addables.length > 0 || !checkFreeSize(i_cat) || (!onLunch && showFreeAddNow == 0)">
                            <button
                                type="button" class="btn btn-block btn-table" [class.active]="show_tab == i_cat"
                                (click)="show_tab = i_cat">
                                {{ing_cat.name}}
                            </button>
                        </ng-container>
                    </ng-container>
                </div>

                <div class="ingredients-wrapper container-fluid">
                    <div class="row bg-white">
                        <ng-container *ngFor="let ing_cat of ingredient_list, let i_cat = index">
                            <ng-container
                                *ngIf="!ing_cat.sizes_free_addables.length > 0 || !checkFreeSize(i_cat) || (!onLunch && showFreeAddNow == 0)">
                                <ng-container *ngFor="let ing of ing_cat.ingredients, let i_ing = index">
                                    <ng-container *ngIf="show_tab == i_cat">
                                        <ng-container *ngIf="ing.is_active && ing.price[current_size_index]">
                                            <div class="ingredient-item col-6 col-md-4 col-lg-3 d-flex flex-column justify-content-between">
                                                <div>                                                    
                                                    <div class="item-added item-added-hidden" id="item-added-{{i_ing}}">
                                                        <p>hinzugefügt</p>
                                                    </div>

                                                    <div class="item-removed item-removed-hidden"
                                                        id="item-removed-{{i_ing}}">
                                                        <p>entfernt</p>
                                                    </div>

                                                    <div class="ingredient-title">
                                                        {{ing.name}}<span class="infobox-link"
                                                            (click)="onOpenInfoBox(ing.id)"></span>
                                                        <!-- <ng-container *ngFor="let marking of ing.markings, let last = last">
                                                    <sup>{{marking.abbreviation}}</sup>
                                                    <ng-container *ngIf="!last"><sup>,</sup></ng-container>
                                                </ng-container> -->
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="ingredient-image d-flex flex-column justify-content-center align-items-center">
                                                        <img *ngIf="ing.image" src="{{image_url}}/admin/{{ing.image}}"
                                                            alt="{{ing.name}}" />
                                                        <img *ngIf="!ing.image" src="assets/img/dummy/sample-ingredient.png"
                                                            alt="">
                                                    </div>
                                                    <div class="ingredient-price  my-2" *ngIf="ing.price[current_size_index].price !=0">
                                                        <ng-container>{{ing.price[current_size_index].price | number:
                                                            '1.2-5'}} €</ng-container>
                                                    </div>
                                                    <div class="ingredient-price  my-2" *ngIf="ing.price[current_size_index].price ==0">
                                                        <ng-container>Gratis</ng-container>
                                                    </div>
                                                    <div class="ingredient-add-remove">
                                                        <!-- buttons -->
                                                        <button type="button" class="mp-btn-minus"
                                                            (click)="onSubIng(i_cat, i_ing)"
                                                            [disabled]="subIngIsDisabled(i_cat, i_ing)">
                                                            <!--<svg-icon src="assets/icons/icon_minus.svg" [svgStyle]="{ 'width.px'30,'fill':'currentColor' }"></svg-icon>-->
                                                            <svg-icon src="assets/icons/icon_minus.svg"
                                                                [svgStyle]="{ 'width': '100%', 'height': '100%','fill':'currentColor' }">
                                                            </svg-icon>
                                                        </button>
                                                        <button type="button" class="mp-btn-plus"
                                                            (click)="onAddIng(i_cat, i_ing)"
                                                            [disabled]="amount[i_cat][i_ing]>=ingredient_list[i_cat].max_addable || amount[i_cat].sum >= ingredient_list[i_cat].max_add_global">
                                                            <!--<svg-icon src="assets/icons/icon_plus.svg" [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>-->
                                                            <svg-icon src="assets/icons/icon_plus.svg"
                                                                [svgStyle]="{ 'width': '100%', 'height': '100%','fill':'currentColor' }">
                                                            </svg-icon>
    
                                                        </button>
                                                        <!-- /buttons -->
                                                    </div>
                                                </div>                                                
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>

</ng-container>

<!-- begin: popup -->
<ng-container *ngIf="showInfoBox">
    <div class="bg-mp-dialog">
        <div class="mp-dialog">
            <div class="title-mp-dialog">
                Allergene & Zusatzstoffe
            </div>
            <div class="cont-mp-dialog">
                <h3 class="mp-font-large-yellow">{{ infoBoxIngredient.name }}</h3>
                <span [innerHTML]="infoBoxIngredient.description"></span>
                <ng-container *ngIf="infoBoxIngredient.description_addition">
                    <span class="description-addition" [innerHTML]="infoBoxIngredient.description_addition"></span>
                </ng-container>
                <br><br>
                <h4>Zusatzstoffe:</h4>
                <ul>
                    <ng-container *ngFor="let marking of infoBoxIngredient.markings">
                        <li *ngIf="marking.type === 'Zusatzstoff'">{{ marking.abbreviation}} - {{ marking.name }}</li>
                    </ng-container>
                </ul>
                <br>
                <h4>Allergene:</h4>
                <ul>
                    <ng-container *ngFor="let marking of infoBoxIngredient.markings">
                        <li *ngIf="marking.type === 'Allergen'">{{ marking.abbreviation}} - {{ marking.name }}</li>
                    </ng-container>
                </ul>
                <br>
                <h4>BITTE BEACHTE:</h4>
                <p>Die Zusatzstoffe und Allergene können sich beim Hinzufügen oder Wechseln von Zutaten ändern.</p>
                <br>
                <div class="btn-mp-dialog text-center">
                    <button class="mp-btn mp-btn-yellow-on-white" (click)="showInfoBox = false">Ok</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- end: popup -->
