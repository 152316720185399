import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()

export class HttpStoreInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let mp_store_alias = '';
        let mp_store_domain = '';

        if( window.location.pathname !== '/' && window.location.pathname.split('/').length === 3) {
            mp_store_alias = window.location.pathname.split('/')[1];
        }

        if( window.location.hostname ){
            mp_store_domain = window.location.hostname;
        }
        const req = request.clone({
            headers: request.headers
                .set('X-MP-STORE-ALIAS', mp_store_alias)
                .set('X-MP-STORE-DOMAIN', mp_store_domain)
                .set('Content-Type', 'application/json')
            // ,
            // setParams: {
            //     XDEBUG_SESSION_START: 'xdbg'
            //    }
        });
        return next.handle(req);
    }
}
